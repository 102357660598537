import { Button } from "react-bootstrap";
import { ReactComponent as IconExibir } from "../../icons/exibir.svg";

import ListCard from "../ListCard";
import IconSVG from "../IconSVG";

export type AssembleiaClienteCardData = {
    clienteId: number;
    clienteNome: string;
    cnpj: string;
    total: string;
    classes: {
        classeNome: string;
        classeTotal: string;
        credores: {
            credorNome: string;
            credorTotal: string;
        }[];
    }[];
}

type AssembleiaClienteCardPropsContent = {
    data: AssembleiaClienteCardData;
    index: number;
    onClick:(index: number) => void;
    skeleton?: never;
}

type AssembleiaClienteCardPropsSkeleton = {
    data?: never;
    index?: never;
    onClick?: never;
    skeleton: true;
}

type AssembleiaClienteCardProps = AssembleiaClienteCardPropsContent | AssembleiaClienteCardPropsSkeleton;

export default function AssembleiaClienteCard(props: AssembleiaClienteCardProps){
    
    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }
    
    return (
        <>
            <ListCard onClick={()=>{ if (props.onClick){ props.onClick(props.index) } }}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.clienteNome}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button 
                        variant="light" 
                        className={`p-1 me-2 ${skeletonButton}`} 
                        title="Exibir" 
                        onClick={() => {
                            if (props.onClick){
                                props.onClick(props.index)
                            } 
                        }}
                    >
                        <IconSVG path={IconExibir} width={32} height={32}/>
                    </Button>
                </ListCard.Action>
            </ListCard>
        </>
    )
}
