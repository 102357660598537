import { mdiCardRemoveOutline, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { AssembleiaCredenciadasResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";
import DashboardAssembleiasCredenciadasCard from "../DashboardAssembleiasCredenciadasCard";

export default function DashboardAssembleiasCredenciadas(){

    const assembleiaService = new AssembleiaService();
    
    const navigate = useNavigate();
    const { handleLogout } = useAuth();

    const [message, setMessage] = useState("");
    
    const { data, isLoading, isRefetching } = useQuery<AssembleiaCredenciadasResponseEntity>(['dashboard', 'credenciadas'], () => fetchData());

    async function fetchData(){
        let resp = await assembleiaService.credenciadas({limite: 10});
        if(resp.Result===99){
            handleLogout();
            navigate("/");
        }
        setMessage(resp.Message);
        return resp;
    }

    function handleVerMais(){
        navigate("/assembleiasCredenciadas");
    }

    return(
        <Card className="card-with-border">
            <Card.Header>
                Assembleias credenciadas
                { isRefetching && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
            </Card.Header>
            <Card.Body>
                {isLoading ? (
                    <DashboardAssembleiasCredenciadasCard skeleton/>
                ) : (
                    message ? (
                        <Alert variant="secondary" className="text-center">{message}</Alert>
                    ) : (
                        <>
                            {!data?.Data?.assembleias.length ? (
                                <Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
                                    <Icon path={mdiCardRemoveOutline} size={1} className="me-2"/>
                                    Nenhum registro encontrado
                                </Alert>
                            ) : data?.Data.assembleias.map((assembleia, index)=>{
                                return (
                                    <DashboardAssembleiasCredenciadasCard
                                        key={index}
                                        data={assembleia}
                                    />
                                )
                            })}
                        </>
                    )
                )}
            </Card.Body>
            <Card.Footer>
                <Button variant="light" className="d-flex align-items-center" onClick={handleVerMais}>Ver mais <Icon path={mdiChevronRight} size={1} className="text-muted"/></Button>
            </Card.Footer>
        </Card>
    )
}