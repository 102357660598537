import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import moment from 'moment';
import { ReactComponent as IconCheck } from "../../icons/check.svg";
import { ReactComponent as IconMais } from "../../icons/mais.svg";
import Icon from '@mdi/react';
import { mdiShareVariant, mdiVideoOutline } from '@mdi/js';

import ListCard from "../ListCard";
import IconSVG from "../IconSVG";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import AssembleiaService from "../../services/AssembleiaService";
import { AssembleiaPostDocumentosRequestEntity, AssembleiaPostDocumentosResponseEntity } from "../../entities/AssembleiaEntity";

export type AssembleiaAtoCardData = {
    assembleiaId: number;
    id: number;
    descricao: string;
    statusAtoId: number;
    chamadaId: number;
    chamadaToken: string;
    dataPrevista: string;
    dataInicio: string;
    dataEncerramento: string;
    chamadaAcesso: boolean;
    statusAtoDescricao: string;
    link: string;
    perguntas: {
        id: number;
        tipoPergunta: string;
        texto: string;
        clienteId?: string;
        clienteNome?: string;
        tag?: string;
        opcoes: {
            id: number;
            nome: string;
        }[];
    }[];
}

type AssembleiaAtoCardPropsContent = {
    data: AssembleiaAtoCardData;
    index: number;
    onClick:(index: number) => void;
    skeleton?: never;
}

type AssembleiaAtoCardPropsSkeleton = {
    data?: never;
    index?: never;
    onClick?: never;
    skeleton: true;
}

type AssembleiaAtoCardProps = AssembleiaAtoCardPropsContent | AssembleiaAtoCardPropsSkeleton;

type Arquivo = {
    id: string;
    value: string;
    arquivo: string;
    mimeType: string;
    nome: string;
    tipo: string;
    tipoId: number;
    loading: boolean;
}

const arquivoInicial : Arquivo = { id: "", value: "", arquivo: "", mimeType: "", nome: "", tipo: "Ata do ato", tipoId: 4, loading: false };

export default function AssembleiaAtoCard(props: AssembleiaAtoCardProps){

    const assembleiaService = new AssembleiaService();
    
    const { handleToast } = useToast();
    const { handleLogout, user } = useAuth();
    const navigate = useNavigate();

    const [enviarDocumentoModal, setEnviarDocumentoModal] = useState(false);
    const [enviarDocumentoLoadingModal, setEnviarDocumentoLoadingModal] = useState(false);
    const [enviarDocumentoArquivo, setEnviarDocumentoArquivo] = useState<Arquivo>(arquivoInicial);

    async function handleEnviarDocumento(){
        setEnviarDocumentoModal(true);
    }

    function handleCloseEnviarDocumentoModal(){
        setEnviarDocumentoModal(false);
    }

    function handleArquivo(event: any){
        setEnviarDocumentoArquivo(p=> { return {...p, loading: true}});
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if(typeof reader.result === "string"){
                let arquivo = {
                    value: event.target.value,
                    arquivo: reader.result,
                    contentType: file.type,
                    nome: file.name,
                    loading: false,
                }
                setEnviarDocumentoArquivo(p => {
                    return {...p, ...arquivo};
                });
            }
        };
    }

    async function handleEnviarDocumentoModal(){
        setEnviarDocumentoLoadingModal(true);

        let data: AssembleiaPostDocumentosRequestEntity = {
            assembleiaAtoId: Number(props.data?.id),
            tipoDocumentoId: Number(enviarDocumentoArquivo.tipoId),
            nome: enviarDocumentoArquivo.nome,
            contentType: enviarDocumentoArquivo.mimeType,
            bytes: enviarDocumentoArquivo.arquivo,
        };
                
        let resp : AssembleiaPostDocumentosResponseEntity = await assembleiaService.postDocumentos(data);
        if(resp.Result!==1){
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Assembleia", resp.Message, 5000, "danger");
        }else{
            handleToast("Assembleia", "Informações salvas com sucesso!", 5000);
        }
        
        setEnviarDocumentoArquivo(arquivoInicial);
        setEnviarDocumentoLoadingModal(false);
        setEnviarDocumentoModal(false);
    }

    function handleClick(){
        navigate(`/chamada/${props.data?.assembleiaId}/${props.data?.id}`);
    }

    function handleCompartilhar(){
        let link = encodeURI(`${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/chamadaConvidado?assembleiaId=${props.data?.assembleiaId}&atoId=${props.data?.id}&chamadaId=${props.data?.chamadaId}&chamadaToken=${props.data?.chamadaToken}`);
        navigator.clipboard.writeText(link);
        handleToast("Assembleia", "Link para acesso convidado copiado com sucesso!", 5000);
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    let dateFormat = moment(props.data?.dataPrevista);
    
    return (
        <>
            <ListCard>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.descricao}</ListCard.Text>
                        <ListCard.Title skeleton={props.skeleton}>{dateFormat.format("DD/MM/yyyy HH:mm")}</ListCard.Title>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    {user?.usuario.grupoId && props.data?.statusAtoId === 1 &&(
                        <Button 
                            variant="light" 
                            className={`p-2 me-2 ${skeletonButton}`} 
                            onClick={() => {
                                if (props.onClick){
                                    props.onClick(props.index)
                                } 
                            }}
                        >
                            Perguntas
                        </Button>
                    )}
                </ListCard.Action>
                <ListCard.Action>
                    {props.data?.statusAtoId === 3 && user?.usuario.grupoId &&(
                        <Button variant="light" className={`p-1 me-4`} title="Adicionar Ata" onClick={()=>{ handleEnviarDocumento() }}>
                            <IconSVG path={IconCheck} width={32} height={32} className="me-2"/> Adicionar ata
                        </Button>
                    )}
                    {props.data?.chamadaAcesso === true && user?.usuario.grupoId &&(
                        <Button variant="light" className={`p-1 me-4`} title="Compartilhar Acesso Convidado" onClick={handleCompartilhar}>
                            <Icon path={mdiShareVariant} size={1} className="me-1"/>
                        </Button>
                    )}
                    {props.data?.chamadaAcesso === true ?
                        <Button onClick={handleClick} className={`p-2 me-2 ${skeletonButton}`} title="Acessar">
                            <Icon path={mdiVideoOutline} size={1} className="me-1"/>
                            Acessar
                        </Button> : <div className="d-flex align-self-center text-muted">{props.data?.statusAtoDescricao}</div>
                    }
                </ListCard.Action>                
            </ListCard>
            
            <Modal show={enviarDocumentoModal} onHide={handleCloseEnviarDocumentoModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Ata</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    { !enviarDocumentoLoadingModal ? (
                        <>
                            <Form.Group className="mb-4 d-flex">
                                <Form.Label className="mb-0" style={{cursor: "pointer"}}>
                                    <div className="mt-2">
                                        <div className={`form-file img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-around form-control p-2 fw-bold`} style={{width: 160, height: 180, borderStyle: 'dashed'}}>
                                            {enviarDocumentoArquivo.loading ? (
                                                <Spinner className="mb-2"/>
                                            ) : (
                                                <>
                                                    {enviarDocumentoArquivo.nome ? (
                                                        <span className="mb-2 text-muted fw-normal" style={{wordBreak: 'break-all', maxHeight: '100%', overflow: 'hidden'}}>{enviarDocumentoArquivo.nome}</span>
                                                    ) : (
                                                        <IconSVG path={IconMais} width={46} height={46} className="mb-4"/>
                                                    )}
                                                    <div>
                                                        {enviarDocumentoArquivo.tipo}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <Form.Control accept="application/pdf" className="d-none" type="file" placeholder="arquivo" value={enviarDocumentoArquivo.value} onChange={(e)=>{ handleArquivo(e) }}/>
                                </Form.Label>
                            </Form.Group>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Modal.Body>
                { !enviarDocumentoLoadingModal && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEnviarDocumentoModal}>Cancelar</Button>
                        <Button variant="primary" onClick={handleEnviarDocumentoModal}>Adicionar</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}