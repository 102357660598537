import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from 'react-select'
import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";

import Layout from "../../components/Layout";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { UsuarioEntity,
    //  UsuarioGetAplicativosResponseEntity, 
     UsuarioGetEmpresasResponseEntity, UsuarioGetGruposResponseEntity, UsuarioGetResponseEntity, UsuarioPostResponseEntity } from "../../entities/UsuarioEntity";
import { usuarioGet,
    //  usuarioGetAplicativos, 
    usuarioGetEmpresas, usuarioGetGrupos, usuarioPost } from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconUsuarios } from "../../icons/usuarios.svg";
import { AsyncSelectStyle } from "../../config/defines";

export default function UsuarioFormulario(){

    const navigate = useNavigate();
	const { id } = useParams();
    const { handleToast } = useToast();
    const { user, handleLogout } = useAuth();

    const [formStatus, setFormStatus] = useState(id ? false : true);
    const [formSaving, setFormSaving] = useState(false);
    const [formRefetching, setFormRefetching] = useState(false);
    const [formNome, setFormNome] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formSenha, setFormSenha] = useState("");
    const [formEmpresa, setFormEmpresa] = useState<{label: string; value: number}>();
    const [formGrupo, setFormGrupo] = useState<{label: string; value: number}>();
    // const [formAplicativos, setFormAplicativos] = useState<{label: string; value: number}[]>();

    const [formEmpresaOptions, setFormEmpresaOptions] = useState<{label: string; value: number}[]>();
    const [formGrupoOptions, setFormGrupoOptions] = useState<{label: string; value: number}[]>();
    // const [formAplicativosOptions, setFormAplicativosOptions] = useState<{label: string; value: number}[]>();

    const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>(['usuario', id], () => fetchData(id), {enabled: id !== undefined});
    const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

    useQuery('empresas', () => fetchDataEmpresas());
    useQuery(['grupos',formEmpresa?.value], () => fetchDataGrupos(formEmpresa?.value));
    // useQuery(['aplicativos',formEmpresa?.value], () => fetchDataAplicativos(formEmpresa?.value));

    useEffect(()=>{
        if(formRefetching){
            refetch();
        }
    // eslint-disable-next-line
    },[formRefetching]);
    
    async function fetchData(id: any){
        if(formStatus && !formRefetching){
            return false;
        }

        let resp: UsuarioGetResponseEntity = await usuarioGet(id);
        if(resp.Result===1 && resp.Data){
            
            setFormNome(resp.Data.nome);
            setFormEmail(resp.Data.email);
            setFormGrupo({ label: `${resp.Data.grupo?.grupoNome}`, value: resp.Data.grupo?.grupoId ?? 1 });
            setFormEmpresa({ label: `${resp.Data.empresa?.empresaNome}`, value: resp.Data.empresa?.empresaId ?? 1 });
            // setFormAplicativos(resp.Data.aplicativos?.map((item)=>{ return { label: `${item.nome}`, value: item.aplicativoId } }));

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Usuário", resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    async function mutateData(data: UsuarioEntity) {
        let resp = await usuarioPost(data);
        return resp;
    }

    function mutateSuccess(resp: UsuarioPostResponseEntity){
        if(resp.Result===1 && resp.Data){
            queryClient.invalidateQueries(['usuario', id]);
            handleToast("Usuário", "Informações salvas com sucesso!", 5000);
            navigate("/usuarioFormulario/"+resp.Data);
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Usuário", resp.Message, 5000, "danger");
        }
        handleCancel();
    }

    async function fetchDataEmpresas(){
        let resp: UsuarioGetEmpresasResponseEntity = await usuarioGetEmpresas();
        if(resp.Result===1 && resp.Data){
            
            setFormEmpresaOptions(resp.Data.map((item)=>{ return { label: `${item.empresaNome}`, value: item.empresaId } }));

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Usuário - Empresas", resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    async function fetchDataGrupos(empresa?: number){
        let resp: UsuarioGetGruposResponseEntity = await usuarioGetGrupos(empresa);
        if(resp.Result===1 && resp.Data){
            
            setFormGrupoOptions(resp.Data.map((item)=>{ return { label: `${item.grupoNome}`, value: item.grupoId } }));

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Usuário - Grupos", resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    // async function fetchDataAplicativos(empresa?: number){
        
    //     let resp: UsuarioGetAplicativosResponseEntity = await usuarioGetAplicativos(empresa);
    //     if(resp.Result===1 && resp.Data){
            
    //         setFormAplicativosOptions(resp.Data.map((item)=>{ return { label: `${item.nome}`, value: item.aplicativoId } }));

    //     }else{
    //         if(resp.Result===99){
    //             handleLogout();
    //             navigate("/");
    //         }
    //         handleToast("Usuário - Aplicativos", resp.Message, 5000, "warning");
    //         handleVoltar();
    //     }

    //     return true;
    // }

    function handleVoltar(){
        navigate("/usuarios");
    }

    async function handleSave(){
        let erros = [];
        if(formNome.length===0){ erros.push("Nome é obrigatório"); }
        if(formEmail.length===0){ erros.push("E-mail é obrigatório"); }
        if(!id && formSenha.length===0){ erros.push("Senha é obrigatório"); }
        if(user?.superUser && !formEmpresa){ erros.push("Empresa é obrigatório"); }
        if(!formGrupo){ erros.push("Grupo é obrigatório"); }
        // if(!formAplicativos){ erros.push("Aplicativos é obrigatório"); }
        if(erros.length>0){
            handleToast("Usuário", "Informações invalidas: "+erros.join(', '), 5000, "warning");
        }else{
            setFormSaving(true);

            const data: UsuarioEntity = {
                usuarioId: id ? Number(id) : undefined,
                nome: formNome,
                email: formEmail,
                senha: formSenha,
                empresa: formEmpresa ? {empresaId: formEmpresa?.value} : undefined,
                grupo: formGrupo ? {grupoId: formGrupo.value} : undefined,
                // aplicativos: formAplicativos ? formAplicativos.map((item)=>{ return { aplicativoId: item.value } }) : undefined,
            }
            
            mutation.mutate(data);
        }
    }

    function handleCancel(){
        setFormRefetching(true);
        setFormStatus(false);
        setFormSaving(false);
    }
    
    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <IconSVG path={IconUsuarios} width={24} height={24} className="me-1"/> Formulário de Usuário
                { (isLoading || isFetching || isRefetching) && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
                <div className="float-right ms-auto" style={{marginTop: -10, marginBottom: -10}}>
                    <Button className="d-flex" variant="dark" onClick={handleVoltar}><Icon path={mdiChevronLeft} size={1}/></Button>
                </div>
            </h5>
            
            <Card className="mb-4">
                <Card.Body>
                    
                    <Form>
                        <Form.Group className="mb-3" controlId="nome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder="Informe aqui o nome" name="nome" value={formNome} onChange={(event)=>{ setFormNome(event.target.value); }} disabled={!formStatus}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" placeholder="Informe aqui o e-mail" name="email" value={formEmail} onChange={(event)=>{ setFormEmail(event.target.value); }} disabled={!formStatus}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="senha">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control type="password" placeholder="Informe aqui a senha" name="senha" value={formSenha} onChange={(event)=>{ setFormSenha(event.target.value); }} disabled={!formStatus}/>
                        </Form.Group>
                        {user?.superUser && (
                            <Form.Group className="mb-3" controlId="empresa">
                                <Form.Label>Empresa</Form.Label>
                                <Select
                                    placeholder={"Selecione a empresa"}
                                    defaultValue={formEmpresa}
                                    value={formEmpresa}
                                    noOptionsMessage={()=>{ return "Nenhuma opção disponivel" }}
                                    options={formEmpresaOptions}
                                    styles={AsyncSelectStyle}
                                    onChange={(value)=>{
                                        if(value){ 
                                            setFormGrupo(undefined); 
                                            // setFormAplicativos(undefined);
                                            setFormEmpresa(value);
                                        }
                                    }}
                                    isDisabled={!formStatus || !!id}
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3" controlId="grupoPermissao">
                            <Form.Label>Grupo de Permissão</Form.Label>
                            <Select
                                placeholder={"Selecione o grupo de permissão"}
                                defaultValue={formGrupo}
                                value={formGrupo || ''}
                                noOptionsMessage={()=>{ return "Nenhuma opção disponivel" }}
                                options={formGrupoOptions}
                                styles={AsyncSelectStyle}
                                onChange={(value)=>{ if(value && typeof value != "string") setFormGrupo(value) }}
                                isDisabled={!formStatus || (user?.superUser && !formEmpresa)}
                            />
                        </Form.Group>

                        
                        {!formStatus ? (
                            <Button className="me-2 p-3" variant="dark" type="button" onClick={()=>{ setFormStatus(true); }} style={{width: 180}}>Editar Informações</Button>
                        ) : (
                            <>
                                <Button className="me-2 p-3" variant="primary" type="button" onClick={handleSave} style={{width: 180}} disabled={formSaving}>
                                    {formSaving ? (
                                        <><Spinner animation="border" size="sm" className="me-2"/> Salvando</>
                                    ):(
                                        "Salvar Informações"
                                    )}
                                </Button>
                                {id && (<Button className="me-2 p-3" variant="danger" type="button" onClick={handleCancel} style={{width: 180}} disabled={formSaving}>Cancelar</Button>)}
                            </>
                        )}
                    </Form>

                </Card.Body>
            </Card>
        </Layout>
    )
}