import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Button, Card, Form, Spinner, FloatingLabel, Modal } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import DatePicker from "react-datepicker";
import moment, { Moment } from 'moment';
import InputMask from "react-input-mask";


import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { queryClient } from "../../services/QueryClientService";
import PessoaService from "../../services/PessoaService";
import { PessoaGetResponseEntity, PessoaAtualizarRequestEntity, PessoaAtualizarResponseEntity } from "../../entities/PessoaEntity";
import Layout from "../../components/Layout";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconAssembleias } from "../../icons/assembleias.svg";

const screenConfig = {
    toastTitle: "Pessoa",
    queryId: "pessoa",
    routeBack: "/dashborad",
    routeSubmit: "/pessoaFormulario/",
}

export default function PessoaFormulario(){

    const pessoaService = new PessoaService();

    const navigate = useNavigate();
    const { handleToast } = useToast();
    
    const { handleLogout, user } = useAuth();
    
    const [formStatus, setFormStatus] = useState(user?.usuario.id ? false : true);
    const [formSaving, setFormSaving] = useState(false);
    const [formRefetching, setFormRefetching] = useState(false);
    
    const [formTipo, setFormTipo] = useState<number>();
    const [formEmail, setFormEmail] = useState("");
    const [formSenha, setFormSenha] = useState("");
    const [formNome, setFormNome] = useState("");
    const [formCPF, setFormCPF] = useState("");
    const [formTelefone, setFormTelefone] = useState("");
    const [formCelular, setFormCelular] = useState("");
    const [formDataNascimento, setFormDataNascimento] = useState<Moment>();

    const [removeModal, setRemoveModal] = useState(false);
    const [removeLoadingModal, setRemoveLoadingModal] = useState(false);

    const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>([screenConfig.queryId, user?.usuario.id], () => fetchData(user?.usuario.id));
    const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

    useEffect(()=>{
        if(formRefetching){
            refetch();
        }
    // eslint-disable-next-line
    },[formRefetching]);
    
    async function fetchData(id: number | undefined){
        if((formStatus && !formRefetching) || id === undefined ){
            return false;
        }

        let resp: PessoaGetResponseEntity = await pessoaService.get({id: id});
        if(resp.Result===1 && resp.Data){
            
            setFormEmail(resp.Data.email);
            setFormNome(resp.Data.nome);
            setFormCPF(resp.Data.cpfCnpj);
            setFormTelefone(resp.Data.telefone);
            setFormCelular(resp.Data.celular);
            if(resp.Data.dataNascimento && resp.Data.dataNascimento.length>=1){
                setFormDataNascimento(moment(resp.Data.dataNascimento));
            }
            setFormTipo(resp.Data.tipoId);
            
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    async function mutateData(data: PessoaAtualizarRequestEntity) {
        return await pessoaService.atualizar(data);
    }
   
    function mutateSuccess(resp: PessoaAtualizarResponseEntity){
        if(resp.Result===1){
            queryClient.invalidateQueries([screenConfig.queryId, user?.usuario.id]);
            handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
        }
        setFormSaving(false);
        setFormStatus(false);

    }

    function handleVoltar(){
        navigate(screenConfig.routeBack);
    }

    async function handleSave(){
        
        setFormSaving(true);
        const data: PessoaAtualizarRequestEntity = {
            id: user?.usuario.id ?? undefined,
            nome: formNome,
            email: formEmail,
            cpfCnpj: formCPF,
            dataNascimento: formDataNascimento ? formDataNascimento.toISOString() : undefined,
            telefone: formTelefone,
            celular: formCelular,
            tipoId: Number(formTipo),
            senha: formSenha === "" ? undefined : formSenha,
        }
        
        mutation.mutate(data);
        
    }

    function handleCancel() {
        setFormRefetching(true);
        setFormStatus(false);
        setFormSaving(false);
    }

    function handleRemoveModal() {
        setRemoveModal(true);
    }

    function handleRemoveCloseModal(){
        if(!removeLoadingModal){
            setRemoveModal(false);
        }
    }

    async function handleRemove(){
        setRemoveLoadingModal(true);
        let resp = await pessoaService.delete(Number(user?.usuario.id));
        if(resp.Result===1){
            handleLogout();
            navigate("/");
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
        }
        setRemoveModal(false);
        setRemoveLoadingModal(false);
    }

    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center">
                <IconSVG path={IconAssembleias} width={24} height={24} className="me-1"/> Formulário de Pessoa
                { (isLoading || isFetching || isRefetching) && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
                <div className="float-right ms-auto" style={{marginTop: -10, marginBottom: -10}}>
                    <Button className="d-flex" variant="dark" onClick={handleVoltar}><Icon path={mdiChevronLeft} size={1}/> Voltar</Button>
                </div>
            </h5>                            

            {/* INFORMACOES */}
            <Card className="mb-4">
                <Card.Body>
                    <Form>                                                  
                        <FloatingLabel controlId="email" label="Email" className="mb-3">
                            <Form.Control type="text" placeholder="Informe aqui o email" value={formEmail} onChange={(event)=>{ setFormEmail(event.target.value); }} disabled={true}/>
                        </FloatingLabel>

                        <FloatingLabel controlId="nome" label="Nome" className="mb-3">
                            <Form.Control type="text" placeholder="Informe aqui o nome" value={formNome} onChange={(event)=>{ setFormNome(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel>

                        <FloatingLabel controlId="cpf" label="CPF" className="mb-3">
                            <InputMask mask="999.999.999-99" type="text" className="form-control" placeholder="Informe aqui o CPF" value={formCPF} onChange={(event)=>{ setFormCPF(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel> 

                        <FloatingLabel controlId="telefone" label="Telefone" className="mb-3">
                            <InputMask mask="(99) 9999-9999" type="text" className="form-control" placeholder="Informe aqui o telefone" value={formTelefone} onChange={(event)=>{ setFormTelefone(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel>

                        <FloatingLabel controlId="celular" label="Celular" className="mb-3">
                            <InputMask mask="(99) 99999-9999" type="text" className="form-control" placeholder="Informe aqui o celular" value={formCelular} onChange={(event)=>{ setFormCelular(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel>

                        <FloatingLabel controlId="dataNacimento" label="Data Nascimento" className="mb-3 floating-date-picker">
                            <DatePicker
                                disabled={!formStatus}
                                className="form-control"
                                calendarClassName="dropdown-menu show shadow-lg"
                                weekDayClassName={() => { return "fw-light"; }}
                                dayClassName={(date) => { return `btn btn-sm ${formDataNascimento && date.toDateString()===formDataNascimento.toDate().toDateString() ? "btn-primary" : "btn-light"} `; }}
                                monthClassName={() => { return "bg-danger"; }}
                                timeClassName={() => { return "bg-danger"; }}
                                popperModifiers={[
                                    {
                                        name: 'arrow',
                                        options: {
                                            padding: ({ popper, reference }) => ({
                                                right: Math.min(popper.width, reference.width) - Math.min(popper.width, reference.width) + 24,
                                            }),
                                        },
                                    },
                                ]}
                                selected={formDataNascimento ? formDataNascimento.toDate() : undefined}
                                onChange={(date)=>{ setFormDataNascimento(date ? moment(date) : undefined); }}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <InputMask mask="99/99/9999" type="text" className="form-control"/>
                                }
                            />
                        </FloatingLabel>

                        <FloatingLabel controlId="senha" label="Nova senha" className="mb-0,5">
                            <Form.Control type="password" placeholder="Informe aqui a senha" value={formSenha} onChange={(event)=>{ setFormSenha(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel>                        
                        
                        {!formStatus ? (
                            <>
                                <Button className="mt-3 me-2 p-3" variant="dark" type="button" onClick={() => { setFormStatus(true) }} style={{width: 180}} >Editar Informações</Button>
                                <Button className="mt-3 me-2 p-3" variant="danger" type="button" onClick={handleRemoveModal} style={{width: 180}} disabled={formSaving}>Remover Conta</Button>
                            </>
                        ) : (                                                        
                            <>
                                <Form.Text id="passwordHelpBlock" muted className="p-4">
                                    Caso não queira trocar a senha, manter o campo em branco.
                                </Form.Text>
                                <br></br>
                                <Button className="mt-3 me-2 p-3" variant="primary" type="button" onClick={handleSave} style={{width: 180}} disabled={formSaving}>
                                    {formSaving ? (                                               
                                        <><Spinner animation="border" size="sm" className="me-2" /> Salvando</>                                                
                                    ) : (
                                        "Salvar Informações"                                                                                                                               
                                        )}                                    
                                </Button>                                       
                                <Button className="mt-3 me-2 p-3" variant="danger" type="button" onClick={handleCancel} style={{width: 180}} disabled={formSaving}>Cancelar</Button>
                            </>
                        )}
                    </Form>

                </Card.Body>
            </Card>

            <Modal show={removeModal} onHide={handleRemoveCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remover Conta</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    { !removeLoadingModal ? (
                        "Deseja realmente remover esta conta?"
                    ) : (
                        <Spinner />
                    )}
                </Modal.Body>
                { !removeLoadingModal && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleRemoveCloseModal}>Cancelar</Button>
                        <Button variant="danger" onClick={handleRemove}>Remover</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </Layout>
    )
}