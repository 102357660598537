import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";
import { useAuth } from "../../context/AuthContext";
import { LoginMenuEntity } from "../../entities/LoginEntity";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconAcessos } from "../../icons/acessos.svg";
import { ReactComponent as IconGrupos } from "../../icons/grupos.svg";
import { ReactComponent as IconUsuarios } from "../../icons/usuarios.svg";

export default function Acessos(){

    const { user } = useAuth();
    const navigate = useNavigate();

    function handleClick(item: string){
        navigate(item);
    }

    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <IconSVG path={IconAcessos} width={24} height={24} className="me-1"/> Acessos
            </h5>
            
            <Row className="mb-4">
                {user?.menus?.includes(LoginMenuEntity.grupos) && (
                    <Col md={12}>
                        <ListCard onClick={()=>{ handleClick("/grupos"); }}>
                            <ListCard.Icon>
                                <IconSVG path={IconGrupos} width={24} height={24} className="text-muted me-3"/>
                            </ListCard.Icon>
                            <ListCard.Content>
                                <div className="flex-fill me-2">
                                    <div className="text-muted fw-lighter">Grupos</div>
                                    <div className="fw-light">Lista de Grupos de usuários para gerenciar permissões</div>
                                </div>
                            </ListCard.Content>
                            <ListCard.Action>
                                <Icon path={mdiChevronRight} size={1} className="text-muted m-2"/>
                            </ListCard.Action>
                        </ListCard>
                    </Col>
                )}
                {user?.menus?.includes(LoginMenuEntity.usuarios) && (
                    <Col md={12}>
                        <ListCard onClick={()=>{ handleClick("/usuarios"); }}>
                            <ListCard.Icon>
                                <IconSVG path={IconUsuarios} width={24} height={24} className="text-muted me-3"/>
                            </ListCard.Icon>
                            <ListCard.Content>
                                <div className="flex-fill me-2">
                                    <div className="text-muted fw-lighter">Usuários</div>
                                    <div className="fw-light">Lista de usuários para gerenciar permissões</div>
                                </div>
                            </ListCard.Content>
                            <ListCard.Action>
                                <Icon path={mdiChevronRight} size={1} className="text-muted m-2"/>
                            </ListCard.Action>
                        </ListCard>
                    </Col>
                )}
            </Row>
        </Layout>
    )
}