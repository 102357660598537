import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCheckboxBlankOutline, mdiCheckboxIntermediate, mdiCheckboxMarkedOutline, mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiUnfoldLessHorizontal, mdiUnfoldMoreHorizontal } from "@mdi/js";
import CheckboxTree from 'react-checkbox-tree';
import Select from 'react-select'

import Layout from "../../components/Layout";
import { useToast } from "../../context/ToastContext";
import { GrupoGetPermissoesResponseEntity, GrupoGetResponseEntity, GrupoPostEntity, GrupoPostResponseEntity } from "../../entities/GrupoEntity";
import { grupoGet, grupoGetPermissoes, grupoPost, usuarioGetEmpresas } from "../../services/ApiService";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../context/AuthContext";
import { queryClient } from "../../services/QueryClientService";
import { UsuarioGetEmpresasResponseEntity } from "../../entities/UsuarioEntity";
import { AsyncSelectStyle } from "../../config/defines";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconGrupos } from "../../icons/grupos.svg";

export default function GrupoFormulario(){

    const navigate = useNavigate();
	const { id } = useParams();
    const { handleToast } = useToast();
    const { user, handleLogout } = useAuth();
    
    const [formStatus, setFormStatus] = useState(id ? false : true);
    const [formSaving, setFormSaving] = useState(false);
    const [formRefetching, setFormRefetching] = useState(false);
    const [formNome, setFormNome] = useState("");
    const [formEmpresa, setFormEmpresa] = useState<{label: string; value: number}>();

    const [formEmpresaOptions, setFormEmpresaOptions] = useState<{label: string; value: number}[]>();
    const [formPermissoesOptions, setFormPermissoesOptions] = useState<any>();
    const [formPermissoesChecked, setFormPermissoesChecked] = useState<string[]>();
    const [formPermissoesExpanded, setFormPermissoesExpanded] = useState<string[]>();
    const [formPermissoesParents, setFormPermissoesParents] = useState<{value: number, parent: number | null}[]>();

    const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>(['grupo', id], () => fetchData(id), {enabled: id !== undefined && formEmpresaOptions !== undefined});
    const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

    useQuery('empresas', () => fetchDataEmpresas());
    useQuery('permissoes', () => fetchDataPermissoes());

    useEffect(()=>{
        if(formRefetching){
            refetch();
        }
    // eslint-disable-next-line
    },[formRefetching]);
    
    async function fetchData(id: any){
        if(formStatus && !formRefetching){
            return false;
        }

        let resp: GrupoGetResponseEntity = await grupoGet(id);
        if(resp.Result===1 && resp.Data){
            
            setFormNome(resp.Data.grupoNome);
                
            let empresa = formEmpresaOptions?.find((item)=> item.value===resp.Data?.empresaId );
            if(empresa){
                setFormEmpresa(empresa);
            }

            setFormPermissoesChecked(resp.Data.relacNodos.map((item)=>{ return `${item.relacNodoId}`; }) ?? undefined);

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Grupo", resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    async function mutateData(data: GrupoPostEntity) {
        let resp = await grupoPost(data);
        return resp;
    }

    function mutateSuccess(resp: GrupoPostResponseEntity){
        if(resp.Result===1 && resp.Data){
            queryClient.invalidateQueries(['grupo', id]);
            handleToast("Grupo", "Informações salvas com sucesso!", 5000);
            navigate("/grupoFormulario/"+resp.Data);
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Grupo", resp.Message, 5000, "danger");
        }
        handleCancel();
    }

    async function fetchDataEmpresas(){
        let resp: UsuarioGetEmpresasResponseEntity = await usuarioGetEmpresas();
        if(resp.Result===1 && resp.Data){
            
            setFormEmpresaOptions(resp.Data.map((item)=>{ return { label: `${item.empresaNome}`, value: item.empresaId } }));

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Grupos - Empresas", resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    async function fetchDataPermissoes(){
        let resp: GrupoGetPermissoesResponseEntity = await grupoGetPermissoes();
        if(resp.Result===1 && resp.Data){
            
            setFormPermissoesOptions([handlePermissoes(resp.Data.root)]);
            setFormPermissoesParents(handlePermissoesParents(resp.Data.root, null));

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Grupos - Permissões", resp.Message, 5000, "warning");
            handleVoltar();
        }

        return true;
    }

    function handlePermissoes(permissao: any){
        let permissaoOption : any = {
            value: permissao.relacNodoId ?? 0,
            label: permissao.recursoNome,
        }

        if(permissao.filhos.length>=1){
            permissaoOption['children'] = permissao.filhos.map((filho: any)=>{ return handlePermissoes(filho); });
        }

        return permissaoOption;
    }

    function handlePermissoesParents(permissao: any, parent: number | null){
        let permissaoParent : any[] = [{
            value: permissao.relacNodoId ?? 0,
            parent: parent,
        }];

        if(permissao.filhos.length>=1){
            permissao.filhos.forEach((filho: any)=>{
                permissaoParent = [...permissaoParent, ...handlePermissoesParents(filho, permissao.relacNodoId)];
            });
        }

        return permissaoParent;
    }

    function handlePermissoesParentsFind(permissao: number){
        let permissoesParents : number[] = [];
        let permissaoParent = formPermissoesParents?.find((itemParent)=>{
            return permissao===itemParent.value;
        })
        if(permissaoParent && permissaoParent.parent){
            permissoesParents.push(permissaoParent.parent);
            permissoesParents = [...permissoesParents, ...handlePermissoesParentsFind(permissaoParent.parent)];
        }

        return permissoesParents;
    }

    function handleVoltar(){
        navigate("/grupos");
    }

    async function handleSave(){
        let erros = [];
        if(formNome.length===0){ erros.push("Nome é obrigatório"); }
        if(user?.superUser && !formEmpresa){ erros.push("Empresa é obrigatório"); }
        if(!formPermissoesChecked || formPermissoesChecked.length===0){ erros.push("Pelo menos uma permissão deve ser marcada"); }
        if(erros.length>0){
            handleToast("Grupos", "Informações invalidas: "+erros.join(', '), 5000, "warning");
        }else{
            setFormSaving(true);

            let permissoes = formPermissoesChecked?.map((item)=>{ return Number(item); }).filter((item)=>{ return item!==0 }) ?? [];
            let permissoesParents : number[] = [];
            permissoes.forEach((item)=>{ permissoesParents = [...permissoesParents, ...handlePermissoesParentsFind(item)]; });
            permissoes = [...permissoes, ...permissoesParents].filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => a - b );
            
            const data: GrupoPostEntity = {
                grupoId: Number(id),
                nome: formNome,
                relacNodos: permissoes,
                empresaId: formEmpresa ? formEmpresa?.value : null
            }
            
            mutation.mutate(data);
        }
    }

    function handleCancel(){
        setFormRefetching(true);
        setFormStatus(false);
        setFormSaving(false);
    }
    
    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <IconSVG path={IconGrupos} width={24} height={24} className="me-1"/> Formulário de Grupo
                { (isLoading || isFetching || isRefetching) && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
                <div className="float-right ms-auto" style={{marginTop: -10, marginBottom: -10}}>
                    <Button className="d-flex" variant="dark" onClick={handleVoltar}><Icon path={mdiChevronLeft} size={1}/></Button>
                </div>
            </h5>
            
            <Card className="mb-4">
                <Card.Body>
                    
                    <Form>
                        <Form.Group className="mb-3" controlId="nome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder="Informe aqui o nome" name="nome" value={formNome} onChange={(event)=>{ setFormNome(event.target.value); }} disabled={!formStatus}/>
                        </Form.Group>
                        {user?.superUser && (
                            <Form.Group className="mb-3" controlId="empresa">
                                <Form.Label>Empresa</Form.Label>
                                <Select
                                    placeholder={"Selecione a empresa"}
                                    defaultValue={formEmpresa}
                                    value={formEmpresa}
                                    noOptionsMessage={()=>{ return "Nenhuma opção disponivel" }}
                                    options={formEmpresaOptions}
                                    styles={AsyncSelectStyle}
                                    onChange={(value)=>{ if(value) setFormEmpresa(value) }}
                                    isDisabled={!formStatus || !!id}
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3" controlId="permissoes">
                            <Form.Label>Permissões</Form.Label>
                            <div className={`form-control ${formStatus ? "" : "disabled"}`}>
                                {!formPermissoesOptions ? (
                                    <Spinner/>
                                ) : (
                                    <CheckboxTree
                                        nodes={formPermissoesOptions}
                                        checked={formPermissoesChecked}
                                        expanded={formPermissoesExpanded}
                                        checkModel={"all"}
                                        onCheck={checked => setFormPermissoesChecked( checked ) }
                                        onExpand={expanded => setFormPermissoesExpanded( expanded ) }
                                        showExpandAll
                                        showNodeIcon={false}
                                        icons={{
                                            check: <Icon path={mdiCheckboxMarkedOutline} size={0.8} />,
                                            uncheck: <Icon path={mdiCheckboxBlankOutline} size={0.8} />,
                                            halfCheck: <Icon path={mdiCheckboxIntermediate} size={0.8} />,
                                            expandClose: <Icon path={mdiChevronRight} size={1} className="text-secondary" />,
                                            expandOpen: <Icon path={mdiChevronDown} size={1} />,
                                            expandAll: <Icon path={mdiUnfoldMoreHorizontal} size={1} />,
                                            collapseAll: <Icon path={mdiUnfoldLessHorizontal} size={1} />,
                                        }}
                                        disabled={!formStatus}
                                    />
                                )}
                            </div>
                        </Form.Group>
                        
                        {!formStatus ? (
                            <Button className="me-2 p-3" variant="dark" type="button" onClick={()=>{ setFormStatus(true); }} style={{width: 180}}>Editar Informações</Button>
                        ) : (
                            <>
                                <Button className="me-2 p-3" variant="primary" type="button" onClick={handleSave} style={{width: 180}} disabled={formSaving}>
                                    {formSaving ? (
                                        <><Spinner animation="border" size="sm" className="me-2"/> Salvando</>
                                    ):(
                                        "Salvar Informações"
                                    )}
                                </Button>
                                {id && (<Button className="me-2 p-3" variant="danger" type="button" onClick={handleCancel} style={{width: 180}} disabled={formSaving}>Cancelar</Button>)}
                            </>
                        )}
                    </Form>

                </Card.Body>
            </Card>
        </Layout>
    )
}