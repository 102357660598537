import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import moment from 'moment';

import ListCard from "../ListCard";
import IconSVG from "../IconSVG";
import { ReactComponent as IconExibir } from "../../icons/exibir.svg";

type DashboardAssembleiasProximasFinalizadasPropsContent = {
    data: any;
    skeleton?: never;
}

type DashboardAssembleiasProximasFinalizadasPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type DashboardAssembleiasProximasFinalizadasProps = DashboardAssembleiasProximasFinalizadasPropsContent | DashboardAssembleiasProximasFinalizadasPropsSkeleton;

export default function DashboardAssembleiasProximasFinalizadasCard(props: DashboardAssembleiasProximasFinalizadasProps){

    const navigate = useNavigate();
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/assembleia/"+props.data.assembleiaId);
        }
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome da empresa</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.clientes.join(", ") ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>N.º do processo</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.numeroProcesso ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Ato</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.ato?.data ? moment(props.data?.ato?.data).format("DD/MM/yyyy HH:mm") : ""} - {props.data?.ato?.descricao}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button variant="light" className={`p-1 me-2 ${skeletonButton}`} title="Exibir">
                        <IconSVG path={IconExibir} width={32} height={32}/>
                    </Button>
                </ListCard.Action>
            </ListCard>
        </>
    )
}