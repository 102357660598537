import { Col, Dropdown, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconUsuario } from "../../icons/usuario.svg";

import { useAuth } from "../../context/AuthContext";
import IconSVG from "../IconSVG";

export default function Header() {

	const { user, handleLogout } = useAuth();
	const navigate = useNavigate();

	async function handleSair() {
		handleLogout();
		navigate("/");
	}

	async function handlePerfil() {
		navigate("/pessoaFormulario");
	}

	if(!user) {
		return <></>
	}

	return (
		<Row className="topbar">
			<Col className="fs-4 fw-lighter pt-2 pb-md-2 d-flex align-items-center">
				{user?.welcomeMessage}
			</Col>			
			<Col md="auto" className="fs-6 fw-lighter pt-2 pb-2 d-flex align-items-center">				
				<div className="p-2">
					{user?.usuario.nome}
				</div> 
				<Dropdown>
					<Dropdown.Toggle variant="white" className="d-flex dropdown-no-caret p-0">
						<div >
							<IconSVG path={IconUsuario} />
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu className="shadow">
						<Dropdown.Item href="#" onClick={handlePerfil}>Meus Dados</Dropdown.Item>
						<Dropdown.Item href="#" onClick={handleSair}>Sair</Dropdown.Item>

					</Dropdown.Menu>
				</Dropdown>
			</Col>



		</Row>


	)
}