import ApiService from "./ApiService";
import { ApiResponseEntity } from "../entities/ApiResponseEntity";
import {
    CredenciamentoGetChamadaHorarioResponseEntity,
    CredenciamentoGetCredoresResponseEntity,
    CredenciamentoGetResponseEntity,
    CredenciamentoPostDocumentosRequestEntity,
    CredenciamentoPostDocumentosResponseEntity,
    CredenciamentoPostRequestEntity,
    CredenciamentoPostResponseEntity,
} from "../entities/CredenciamentoEntity";

export default class CredenciamentoService extends ApiService {

    public async get(data: {assembleiaId: string}): Promise<CredenciamentoGetResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<CredenciamentoGetResponseEntity>(`/api/v1/Credenciamento/Get?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getCredores(data: {assembleiaId: string, filtro?: string, assembleiaClienteId?: string, classeId?: string}): Promise<CredenciamentoGetCredoresResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<CredenciamentoGetCredoresResponseEntity>(`/api/v1/Credenciamento/GetCredores?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    // public async postProcurador(data: CredenciamentoPostProcuradorRequestEntity){
    //     let user = this.user();
    //     let response;
    //     try{
    //         let req = await this.request.post<CredenciamentoPostProcuradorResponseEntity>("/api/v1/Credenciamento/PostProcurador", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
    //         response = req.data;
    //     }catch(e){
    //         return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    //     }
    //     return response;
    // }

    public async postDocumentos(data: CredenciamentoPostDocumentosRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CredenciamentoPostDocumentosResponseEntity>("/api/v1/Credenciamento/PostDocumentos", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async post(data: CredenciamentoPostRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<CredenciamentoPostResponseEntity>("/api/v1/Credenciamento/Post", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getChamadaHorario(id: string): Promise<CredenciamentoGetChamadaHorarioResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<CredenciamentoGetChamadaHorarioResponseEntity>(`/api/v1/Credenciamento/GetChamadaHorario?assembleiaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteDocumentos(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Credenciamento/DeleteDocumento?credorDocumentoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async delete(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Credenciamento?credorRepresentadoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

}