import React from "react";
import { ReactNode } from "react";
import { Card, Col } from "react-bootstrap";

type ListCardProps = {
    children: any;
    onClick?: () => void;
}

type IconProps = {
    children: ReactNode | ReactNode[];
}

type ContentProps = {
    children: ReactNode | ReactNode[];
}

type TitleProps = {
    children: ReactNode | ReactNode[];
    skeleton?: true;
}

interface TextProps extends React.HTMLProps<HTMLDivElement> {
    children: ReactNode | ReactNode[];
    skeleton?: true;
}

type ActionProps = {
    children: ReactNode | ReactNode[];
}

const ListCard = ({children, onClick}: ListCardProps) => {
    
    let subComponentList = Object.keys(ListCard);

    let subComponents = subComponentList.map((key) => {
        return React.Children.map(children, (child) => {
            return child.type.displayName === key ? child : false
        });
    });

    return (
        <Card className="mb-1 list-card" role={onClick ? "button" : ""} onClick={onClick}>
            <Card.Body className="d-flex align-items-center">
                {subComponents.map((component) => component)}
            </Card.Body>
        </Card>
    );
}

const Icon = ({children}: IconProps) => {
    return (
        <div className="d-lg-flex  align-items-start">
            { !Array.isArray(children) ? children : children.filter((item)=>{ return item }) }
        </div>
    )
}
Icon.displayName =  "Icon";

const Content = ({children}: ContentProps) => {
    return (
        <div className="d-lg-flex align-items-start flex-fill flex-wrap">
            { !Array.isArray(children) ? children : children.filter((item)=>{ return item }) }
        </div>
    )
}
Content.displayName =  "Content";

const Item = ({children}: ContentProps) => {
    return (
        <Col>
            { !Array.isArray(children) ? children : children.filter((item)=>{ return item }) }
        </Col>
    )
}
Item.displayName =  "Item";

const Title = ({children, skeleton}: TitleProps) => {
    return (
        <div className={`line-1 text-muted fw-lighter ${skeleton ? "skeleton skeleton-text skeleton-text-title" : ""} `} title={!Array.isArray(children) ? children?.toString() : ""}>
            { !Array.isArray(children) ? children : children.filter((item)=>{ return item }) }
        </div>
    )
}
Title.displayName =  "Title";

const Text = ({children, skeleton, ...rest}: TextProps) => {
    return (
        <div className={`line-1 fw-light ${skeleton ? "skeleton skeleton-text skeleton-text-content" : ""} `} title={!Array.isArray(children) ? children?.toString() : ""} {...rest}>
            { !Array.isArray(children) ? children : children.filter((item)=>{ return item }) }
        </div>
    )
}
Text.displayName =  "Text";

const Action = ({children}: ActionProps) => {
    return (
        <div className="ms-4 d-flex flex-nowrap">
            { !Array.isArray(children) ? children : children.filter((item)=>{ return item }) }
        </div>
    )
}
Action.displayName =  "Action";

ListCard.Icon = Icon;
ListCard.Content = Content;
ListCard.Item = Item;
ListCard.Title = Title;
ListCard.Text = Text;
ListCard.Action = Action;
export default ListCard;