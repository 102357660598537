import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { screens, screensPublics } from "../config/screens";
import { LoginEntity, LoginRecuperarSenhaResponseEntity } from "../entities/LoginEntity";
import { login, loginRecuperarSenha } from "../services/ApiService";
import { queryClient } from "../services/QueryClientService";

type AuthContextProps = {
	user: LoginEntity | undefined;
	aplicativo: any;
	routeActive: string;
	handleLogin: (email: string, password: string) => Promise<true | string>;
	handleLogout: () => void;
	handleRecuperarSenha: (email: string, cpf: string, cnpj: string) => Promise<LoginRecuperarSenhaResponseEntity>;
	handleAplicativo: (id: number) => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth(){
	return useContext(AuthContext);
}

type AuthProviderProps = {
    children: ReactNode;
}

export default function AuthProvider({children}: AuthProviderProps){

    const location = useLocation();
    const navigate = useNavigate();
	
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<LoginEntity | undefined>();
	const [aplicativo, setAplicativo] = useState<number>(1);
	const [routeActive, setRouteActive] = useState<string>("");

	useEffect(()=>{
		let routeActive : string | false = false;
		screens.forEach((screen)=>{
			if(screen.route===location.pathname && screen.showSidebar){
				routeActive = location.pathname;
			}
		});
		if(routeActive===false){
			screensPublics.forEach((screen)=>{
				if(screen.route===location.pathname){
					routeActive = location.pathname;
				}
			});
		}
		if(routeActive!==false){
			setRouteActive(routeActive);
		}
	},[location]);

	useEffect(()=>{
		let sessionUser = sessionStorage.getItem("user");
		if(sessionUser){
			let userJson = JSON.parse(sessionUser);
			setUser(userJson);
		}

		let sessionAplicativo = sessionStorage.getItem("aplicativo");
		if(sessionAplicativo){
			let aplicativoInt = parseInt(sessionAplicativo);
			setAplicativo(aplicativoInt);
		}

		setLoading(false);
	},[]);

	async function handleLogin(email: string, password: string){
		let response = await login(email, password);
        if(response.Result===1 && response.Data){
			sessionStorage.setItem("user", JSON.stringify(response.Data));
            setUser(response.Data);
			return true;
        }

        return response.Message;
	}

	async function handleRecuperarSenha(email: string, cpf: string, cnpj: string){
		let response = await loginRecuperarSenha(email, cpf, cnpj);
        return response;
	}

	function handleLogout(){
		setUser(undefined);
		sessionStorage.removeItem("user");
		queryClient.invalidateQueries();
		navigate("/");
		return;
	}

	async function handleAplicativo(id: number){
		sessionStorage.setItem("aplicativo", id.toString());
		queryClient.invalidateQueries();
		setAplicativo(id);
	}

    const value = {
		user,
		aplicativo,
		routeActive,
		handleLogin,
		handleLogout,
		handleRecuperarSenha,
		handleAplicativo
	}

	if(loading){
		return <></>;
	}

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
}