import { mdiCardRemoveOutline, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Alert, Button, Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { AssembleiaPublicasResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";
import DashboardAssembleiasProximasFinalizadasCard from "../DashboardAssembleiasProximasFinalizadasCard";
import Tabs from "../Tabs";

export default function DashboardAssembleiasProximasFinalizadas(){

    const assembleiaService = new AssembleiaService();
    
    const navigate = useNavigate();
    const { handleLogout } = useAuth();

    const [status, setStatus] = useState("proximas");
    const [message, setMessage] = useState("");
    
    const { data, isLoading, isRefetching } = useQuery<AssembleiaPublicasResponseEntity>(['dashboard', 'proximas', 'finalizadas', status], () => fetchData(status));

    async function fetchData(status: string){
        let resp = await assembleiaService.publicas({pagina: 1, limite: 10, status: status});
        if(resp.Result===99){
            handleLogout();
            navigate("/");
        }
        setMessage(resp.Message);
        return resp;
    }

    function handleVerMais(){
        navigate(`/assembleiasPublicas/${status}`);
    }

    return(
        <Card className="card-with-border">
            <Card.Header className="d-flex flex-row justify-content-start align-items-center">
                <Tabs
                    active={status}
                    options={[
                        {value: 'proximas', label: 'Próximas'},
                        {value: 'finalizadas', label: 'Finalizadas'}
                    ]}
                    onChange={(value)=>{ setStatus(value); }}
                />
                { isRefetching && (
                    <Spinner size="sm" className="ms-2 mb-2" variant="secondary" />
                )}
            </Card.Header>
            <Card.Body>
                {isLoading ? (
                    <DashboardAssembleiasProximasFinalizadasCard skeleton/>
                ) : (
                    message ? (
                        <Alert variant="secondary" className="text-center">{message}</Alert>
                    ) : (
                        <>
                            {!data?.Data?.assembleias.length ? (
                                <Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
                                    <Icon path={mdiCardRemoveOutline} size={1} className="me-2"/>
                                    Nenhum registro encontrado
                                </Alert>
                            ) : data?.Data.assembleias.map((assembleia, index)=>{
                                return (
                                    <DashboardAssembleiasProximasFinalizadasCard
                                        key={index}
                                        data={assembleia}
                                    />
                                )
                            })}
                        </>
                    )
                )}
            </Card.Body>
            <Card.Footer>
                <Button variant="light" className="d-flex align-items-center" onClick={handleVerMais}>Ver mais <Icon path={mdiChevronRight} size={1} className="text-muted"/></Button>
            </Card.Footer>
        </Card>
    )
}