import ApiService from "./ApiService";

import {
    HomologacaoGetAssembleiaResponseEntity,
    HomologacaoGetAssembleiasResponseEntity,
    HomologacaoGetCredenciamentosResponseEntity,
    HomologacaoGetDocumentoResponseEntity,
    HomologacaoPostAprovarRequestEntity,
    HomologacaoPostAprovarResponseEntity,
    HomologacaoPostReprovarRequestEntity,
    HomologacaoPostReprovarResponseEntity
} from "../entities/HomologacaoEntity";

export default class HomologacaoService extends ApiService {

    public async getAssembleias(data: {pagina: number, filtro?: string, dataInicio?: string, dataFim?: string}): Promise<HomologacaoGetAssembleiasResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<HomologacaoGetAssembleiasResponseEntity>(`/api/v1/Homologacao/GetAssembleias?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getAssembleia(id: string): Promise<HomologacaoGetAssembleiaResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<HomologacaoGetAssembleiaResponseEntity>(`/api/v1/Homologacao/GetAssembleia?assembleiaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getCredenciamentos(data: {assembleiaId: number, pessoaTipoId: number}): Promise<HomologacaoGetCredenciamentosResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<HomologacaoGetCredenciamentosResponseEntity>(`/api/v1/Homologacao/GetCredenciamentos?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getDocumento(data: {documentoId: number}): Promise<HomologacaoGetDocumentoResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<HomologacaoGetDocumentoResponseEntity>(`/api/v1/Homologacao/GetDocumento?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postAprovar(data: HomologacaoPostAprovarRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<HomologacaoPostAprovarResponseEntity>("/api/v1/Homologacao/PostAprovar", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postReprovar(data: HomologacaoPostReprovarRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<HomologacaoPostReprovarResponseEntity>("/api/v1/Homologacao/PostReprovar", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

}