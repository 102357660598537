import { ReactNode } from "react";
import { Container } from "react-bootstrap";

type MainProps = {
    children: ReactNode[];
}

export default function Main({children}: MainProps){
	return(
		<Container className="col-md-8 ms-sm-auto col-lg-8 col-xl-9 px-md-5 me-0 mb-3">
			{ children.filter((item)=>{ return item }) }
		</Container>
	)
}