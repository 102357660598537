import ApiService from "./ApiService";

import {
    ClienteAutoCompleteCidadesResponseEntity,
    ClienteAutoCompleteClientesResponseEntity,
    ClienteGetEstadosResponseEntity,
    ClienteGetResponseEntity,
    ClientePostRequestEntity,
    ClientePostResponseEntity
} from "../entities/ClienteEntity";

export default class ClienteService extends ApiService {

    public async get(id: number): Promise<ClienteGetResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<ClienteGetResponseEntity>(`/api/v1/Cliente/Get?clienteId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async post(data: ClientePostRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<ClientePostResponseEntity>("/api/v1/Cliente/Post", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async autoCompleteClientes(search: string): Promise<ClienteAutoCompleteClientesResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<ClienteAutoCompleteClientesResponseEntity>(`/api/v1/Cliente/AutoCompleteClientes?filtro=${search}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getEstados(): Promise<ClienteGetEstadosResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<ClienteGetEstadosResponseEntity>(`/api/v1/Cliente/GetEstados`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async autoCompleteCidades(search?: string, estado?: string): Promise<ClienteAutoCompleteCidadesResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<ClienteAutoCompleteCidadesResponseEntity>(`/api/v1/Cliente/AutoCompleteCidades?filtro=${search}&uf=${estado}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }
}