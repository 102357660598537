import ApiService from "./ApiService";
import { ApiResponseEntity } from "../entities/ApiResponseEntity";
import {
    AssembleiaAutocompleteEmpresasResponseEntity,
    AssembleiaCredenciadasResponseEntity,
    AssembleiaGetAssembleiasResponseEntity,
    AssembleiaGetAtosResponseEntity,
    AssembleiaGetClientesResponseEntity,
    AssembleiaGetCredoresResponseEntity,
    AssembleiaGetPassadasResponseEntity,
    AssembleiaGetResumoResponseEntity,
    AssembleiaPostAtosRequestEntity, 
    AssembleiaPostAtoResponseEntity, 
    AssembleiaPostClienteRequestEntity, 
    AssembleiaPostClienteResponseEntity, 
    AssembleiaPostCredoresRequestEntity, 
    AssembleiaPostCredoresResponseEntity, 
    AssembleiaPostCredorRequestEntity, 
    AssembleiaPostCredorResponseEntity, 
    AssembleiaPostDocumentosRequestEntity, 
    AssembleiaPostDocumentosResponseEntity, 
    AssembleiaPostRequestEntity, 
    AssembleiaPostResponseEntity, 
    AssembleiaPostPerguntaRequestEntity, 
    AssembleiaPostPerguntaResponseEntity, 
    AssembleiaPublicasResponseEntity,
    AssembleiaGetResponseEntity,
    AssembleiaGetDocumentoResponseEntity,
    AssembleiaUpdateCredorRequestEntity,
    AssembleiaUpdateCredorResponseEntity
} from "../entities/AssembleiaEntity";

export default class AssembleiaService extends ApiService {

    public async getAssembleias(data: {pagina: number, filtro?: string, dataInicio?: string, dataFim?: string}): Promise<AssembleiaGetAssembleiasResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetAssembleiasResponseEntity>(`/api/v1/Assembleia/GetAssembleias?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async get(data: {assembleiaId: number}): Promise<AssembleiaGetResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data]}).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetResponseEntity>(`/api/v1/Assembleia/Get?assembleiaId=${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getPublica(data: {assembleiaId: number}): Promise<AssembleiaGetResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data]}).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetResponseEntity>(`/api/v1/Assembleia/Get/Publica?assembleiaId=${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async autoCompleteEmpresas(search: string): Promise<AssembleiaAutocompleteEmpresasResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<AssembleiaAutocompleteEmpresasResponseEntity>(`/api/v1/Assembleia/AutoCompleteEmpresas?filtro=${search}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async post(data: AssembleiaPostRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostResponseEntity>("/api/v1/Assembleia/Post", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postPergunta(data: AssembleiaPostPerguntaRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostPerguntaResponseEntity>("/api/v1/Assembleia/PostPergunta", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getPassadas(data: {pagina: number, filtro?: string, periodoInicial?: string, periodoFinal?: string}): Promise<AssembleiaGetPassadasResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetPassadasResponseEntity>(`/api/v1/Assembleia/GetPassadas?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getResumo(id: string): Promise<AssembleiaGetResumoResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<AssembleiaGetResumoResponseEntity>(`/api/v1/Assembleia/GetResumo?assembleiaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postAto(data: AssembleiaPostAtosRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostAtoResponseEntity>("/api/v1/Assembleia/PostAtos", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getAtos(data: {assembleiaId: number}): Promise<AssembleiaGetAtosResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetAtosResponseEntity>(`/api/v1/Assembleia/GetAtos?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCliente(data: AssembleiaPostClienteRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostClienteResponseEntity>("/api/v1/Assembleia/PostCliente", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getClientes(assembleiaId: number): Promise<AssembleiaGetClientesResponseEntity> {
        let user = this.user();
        let response;
        try{
            let req = await this.request.get<AssembleiaGetClientesResponseEntity>(`/api/v1/Assembleia/GetClientes?assembleiaId=${assembleiaId}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCredor(data: AssembleiaPostCredorRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostCredorResponseEntity>("/api/v1/Assembleia/PostCredor", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async updateCredor(data: AssembleiaUpdateCredorRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaUpdateCredorResponseEntity>("/api/v1/Assembleia/UpdateCredor", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postCredores(data: AssembleiaPostCredoresRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostCredoresResponseEntity>("/api/v1/Assembleia/PostCredores", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async getCredores(data: {assembleiaClienteId: number}): Promise<AssembleiaGetCredoresResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetCredoresResponseEntity>(`/api/v1/Assembleia/GetCredores?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }
    
    public async getDocumento(data: {documentoId: number}): Promise<AssembleiaGetDocumentoResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaGetDocumentoResponseEntity>(`/api/v1/Assembleia/GetDocumento?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async postDocumentos(data: AssembleiaPostDocumentosRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<AssembleiaPostDocumentosResponseEntity>("/api/v1/Assembleia/PostDocumento", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteCredor(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Assembleia/DeleteCredor?credorId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteDocumento(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Assembleia/DeleteDocumento?documentoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteCliente(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Assembleia/DeleteCliente?assembleiaClienteId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async deleteAto(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Assembleia/DeleteAto?atoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async delete(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Assembleia/Delete?assembleiaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async credenciadas(data: {limite?: number, filtro?: string, dataInicio?: string, dataFim?: string}): Promise<AssembleiaCredenciadasResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaCredenciadasResponseEntity>(`/api/v1/Assembleia/Credenciadas?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async publicas(data: {pagina: number, limite?: number, status?: string, filtro?: string, dataInicio?: string, dataFim?: string}): Promise<AssembleiaPublicasResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<AssembleiaPublicasResponseEntity>(`/api/v1/Assembleia/Publicas?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }
}