import { mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AlertaGetListEntity } from "../../entities/AlertaEntity";
import { alertaDelete } from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import ListCard from "../ListCard";

type AlertasCardPropsContent = {
    data: AlertaGetListEntity;
    skeleton?: never;
}

type AlertasCardPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type AlertasCardProps = AlertasCardPropsContent | AlertasCardPropsSkeleton;

export default function AlertasCard(props: AlertasCardProps){

    const navigate = useNavigate();
    const { handleLogout } = useAuth();
    const { handleToast } = useToast();
    
    const [removeModal, setRemoveModal] = useState(false);
    const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
    const [removed, setRemoved] = useState(false);

    function handleClickRemove(e: any){
        e.stopPropagation();
        setRemoveModal(true);
    }

    async function handleRemove(){
        if(!props.skeleton && props.data){
            setRemoveLoadingModal(true);
            let resp = await alertaDelete(props.data.id);
            if(resp.Result===1){
                queryClient.refetchQueries('alertas');
                queryClient.invalidateQueries(['alerta', props.data.id]);
                setRemoved(true);
            }else{
                if(resp.Result===99){
                    handleLogout();
                    navigate("/");
                }
                handleToast("Alerta", resp.Message, 5000, "danger");
            }
            setRemoveModal(false);
            setRemoveLoadingModal(false);
        }
    }

    function handleCloseModal(){
        if(!removeLoadingModal){
            setRemoveModal(false);
        }
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    if(removed){
        return <></>
    }

    let dateFormat = moment(props.data?.data);

    return (
        <>
            <ListCard>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Titulo</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.titulo ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Texto</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.texto ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Data</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{dateFormat.format("DD/MM/yyyy HH:mm")}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Push</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.tipo===1 ? "Sim" : "Não"}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Enviado para</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.pessoaNome ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}><Icon path={mdiTrashCanOutline} size={1} className="text-danger"/></Button>
                </ListCard.Action>
            </ListCard>

            <Modal show={removeModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remover Alerta</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    { !removeLoadingModal ? (
                        "Deseja realmente remover este alerta?"
                    ) : (
                        <Spinner />
                    )}
                </Modal.Body>
                { !removeLoadingModal && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
                        <Button variant="danger" onClick={handleRemove}>Remover</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}