import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { UsuarioGetListEntity } from "../../entities/UsuarioEntity";
import { usuarioDelete } from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import ListCard from "../ListCard";

import { ReactComponent as IconFechar } from "../../icons/fechar.svg";
import IconSVG from "../IconSVG";


type UsuariosCardPropsContent = {
    data: UsuarioGetListEntity;
    skeleton?: never;
}

type UsuariosCardPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type UsuariosCardProps = UsuariosCardPropsContent | UsuariosCardPropsSkeleton;

export default function UsuariosCard(props: UsuariosCardProps){

    const navigate = useNavigate();
    const { handleLogout } = useAuth();
    const { handleToast } = useToast();
    
    const [removeModal, setRemoveModal] = useState(false);
    const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
    const [removed, setRemoved] = useState(false);
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/usuarioFormulario/"+props.data.id);
        }
    }

    function handleClickRemove(e: any){
        e.stopPropagation();
        setRemoveModal(true);
    }

    async function handleRemove(){
        if(!props.skeleton && props.data){
            setRemoveLoadingModal(true);
            let resp = await usuarioDelete(props.data.id);
            if(resp.Result===1){
                queryClient.refetchQueries('usuarios');
                queryClient.invalidateQueries(['usuario', props.data.id]);
                setRemoved(true);
            }else{
                if(resp.Result===99){
                    handleLogout();
                    navigate("/");
                }
                handleToast("Usuários", resp.Message, 5000, "danger");
            }
            setRemoveModal(false);
            setRemoveLoadingModal(false);
        }
    }

    function handleCloseModal(){
        if(!removeLoadingModal){
            setRemoveModal(false);
        }
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    if(removed){
        return <></>
    }

    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>E-mail</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.email ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}><IconSVG path={IconFechar} width={32} height={32}/></Button>
                    <Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`}/>
                </ListCard.Action>
            </ListCard>

            <Modal show={removeModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remover Usuário</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    { !removeLoadingModal ? (
                        "Deseja realmente remover este usuário?"
                    ) : (
                        <Spinner />
                    )}
                </Modal.Body>
                { !removeLoadingModal && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
                        <Button variant="danger" onClick={handleRemove}>Remover</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}