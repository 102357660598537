import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline } from "@mdi/js";
import DatePicker from "react-datepicker";
import { useInfiniteQuery } from "react-query";
import moment, { Moment } from 'moment';

import Layout from "../../components/Layout";
import InfiniteScroll from "../../components/InfiniteScroll";
import { useAuth } from "../../context/AuthContext";
import AssembleiasCard from "../../components/AssembleiasCard";
import AssembleiaService from "../../services/AssembleiaService";
import { AssembleiaGetAssembleiasResponseEntity, AssembleiaGetPassadasResponseEntity } from "../../entities/AssembleiaEntity";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconAssembleias } from "../../icons/assembleias.svg";
import { ReactComponent as IconFiltro } from "../../icons/filtro.svg";

export default function Assembleias(){

    const assembleiaService = new AssembleiaService();
    
    const navigate = useNavigate();
    const { aplicativo, handleLogout } = useAuth();

    const [message, setMessage] = useState("");
    const [filtro, setFiltro] = useState(false);
    const [filtroTexto, setFiltroTexto] = useState("");
    const [filtroDataInicial, setFiltroDataInicial] = useState<Moment | null>();
    const [filtroDataFinal, setFiltroDataFinal] = useState<Moment | null>();
    const [permissao, setPermissao] = useState<boolean>(false);

    const [queryFiltro, setQueryFiltro] = useState<{filtro?: string, dataInicio?: string, dataFim?: string}>();
    const { data, hasNextPage, isLoading, isFetching, isFetchingNextPage, isRefetching, fetchNextPage } = useInfiniteQuery<AssembleiaGetPassadasResponseEntity>(['assembleias', aplicativo, queryFiltro], ({pageParam = 1}) => fetchData(pageParam),{ getNextPageParam: (lastPage) => lastPage.Data?.nextPage });

    async function fetchData(pagina: number){
        let resp: AssembleiaGetAssembleiasResponseEntity = await assembleiaService.getAssembleias({pagina: pagina, ...queryFiltro});
        
        if(resp.Result===1 && resp.Data){
            setPermissao(resp.Data?.acessoCadastro);
        }else{
            handleLogout();
            navigate("/");
        }
        setMessage(resp.Message);
        return resp;
    }

    async function fetchMoreData(){
        fetchNextPage();
    }
    
    function handleFiltro(){
        setFiltro(!filtro);
    }

    function handleAplicar(){
        setQueryFiltro({
            filtro: filtroTexto,
            dataInicio: filtroDataInicial?.toISOString(),
            dataFim: filtroDataFinal?.toISOString(),
        });
    }

    function handleLimpar(){
        setFiltroTexto("");
        setFiltroDataInicial(null);
        setFiltroDataFinal(null);
        setQueryFiltro(undefined);
    }

    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <IconSVG path={IconAssembleias} width={24} height={24} className="me-1"/> Assembleias
                { isRefetching && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
                <div className="float-right ms-auto" style={{marginTop: -10, marginBottom: -10}}>
                    <Button className="d-inline-flex" variant="light" title="Filtro" onClick={handleFiltro}><IconSVG path={IconFiltro} width={24} height={24} className="me-1"/></Button>{' '}
                    {permissao === true &&(
                        <Button variant="primary" onClick={()=>{ navigate("/assembleiaFormulario") }}>Cadastrar</Button>
                    )}
                </div>
            </h5>

            {filtro && (
                <Row>
                    <Col>
                        <Card className="mb-4">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col lg={6}>
                                                <InputGroup>
                                                    <InputGroup.Text className="bg-white">Filtro</InputGroup.Text>
                                                    <Form.Control placeholder="Busca por número do processo, empresa, administrador judicial" value={filtroTexto} onChange={(event)=>{ setFiltroTexto(event.target.value); }} disabled={isLoading}/>
                                                </InputGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <InputGroup>
                                                    <InputGroup.Text className="bg-white">Periodo</InputGroup.Text>
                                                    <DatePicker
                                                        placeholderText="Data inicial e final"
                                                        className="form-control"
                                                        calendarClassName="dropdown-menu show shadow-lg"
                                                        weekDayClassName={(date) => { return "fw-light" }}
                                                        dayClassName={(date) => {
                                                            let btn = "btn-light";
                                                            if(filtroDataInicial && filtroDataFinal && date.getTime()>=filtroDataInicial.valueOf() && date.getTime()<=filtroDataFinal.valueOf()){
                                                                btn = "btn-primary";
                                                            }
                                                            return `btn btn-sm ${btn} `
                                                        }}
                                                        popperModifiers={[
                                                            {
                                                                name: 'arrow',
                                                                options: {
                                                                    padding: ({ popper, reference, placement }) => ({
                                                                    right: Math.min(popper.width, reference.width) - Math.min(popper.width, reference.width) + 24,
                                                                    }),
                                                                },
                                                            },
                                                        ]}
                                                        onChange={(datas)=>{
                                                            setFiltroDataInicial(datas[0] ? moment(datas[0]) : null);
                                                            setFiltroDataFinal(datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59) : null);
                                                        }}
                                                        startDate={filtroDataInicial?.toDate()}
                                                        endDate={filtroDataFinal?.toDate()}
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsRange
                                                        disabled={isLoading}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg="auto">
                                        <Button variant="light" className="me-2" onClick={handleLimpar} disabled={isLoading}>Limpar</Button>
                                        <Button variant="primary" onClick={handleAplicar} disabled={isLoading}>Aplicar</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            
            <Row className="mb-4">
                {isLoading ? (
                    <Col md={12}>
                        <AssembleiasCard skeleton />
                    </Col>
                ) : (
                    message ? (
                        <Col md={12}>
                            <Alert variant="secondary" className="text-center">{message}</Alert>
                        </Col>
                    ) : (
                        <>
                            {!data?.pages[0].Data?.assembleias.length ? (
                                <Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
                                    <Icon path={mdiCardRemoveOutline} size={1} className="me-2"/>
                                    Nenhum registro encontrado
                                </Alert>
                            ) : data?.pages.map((dataPage, dataPageIndex)=>{
                                return (
                                    <React.Fragment key={dataPageIndex}>
                                        {dataPage.Data?.assembleias.map((item, index)=>{
                                            return (
                                                <Col md={12} key={index}>
                                                    <AssembleiasCard
                                                        data={item} permission={permissao}
                                                    />
                                                </Col>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )
                )}
                <Col md={12} className="text-center">
                    <InfiniteScroll
                        more={hasNextPage}
                        load={fetchMoreData}
                        loader={<AssembleiasCard skeleton/>}
                        loading={isFetching || isFetchingNextPage}
                    />
                </Col>
            </Row>
        </Layout>
    )
}