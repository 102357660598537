import { Button } from "react-bootstrap";
import { ReactComponent as IconDonwload } from "../../icons/download.svg";

import ListCard from "../ListCard";
import IconSVG from "../IconSVG";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { useNavigate } from "react-router-dom";
import { AssembleiaGetDocumentoResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";
import moment from "moment";

export type AssembleiaDocumentoCardData = {
    id: number;
    tipoDocumentoId: number;
    tipoDocumentoNome: string;
    documentoNome: string;
    descricao: string;
    dataEnvio: string;
}

type AssembleiaDocumentoCardPropsContent = {
    data: AssembleiaDocumentoCardData;
    skeleton?: never;
}

type AssembleiaDocumentoCardPropsSkeleton = {
    data?: never;
    skeleton: true;
}

type AssembleiaDocumentoCardProps = AssembleiaDocumentoCardPropsContent | AssembleiaDocumentoCardPropsSkeleton;

export default function AssembleiaDocumentoCard(props: AssembleiaDocumentoCardProps){

    const [baixarLoading, setBaixarLoading] = useState(false);
    const { handleToast } = useToast();
    const { handleLogout } = useAuth();
    const navigate = useNavigate();

    const assembleiaService = new AssembleiaService();
    
    async function handleBaixarDocumento(){    
        setBaixarLoading(true);   
        let resp : AssembleiaGetDocumentoResponseEntity = await assembleiaService.getDocumento({documentoId: Number(props.data?.id)});
        if(resp.Result===1 && resp.Data){
            let link = document.createElement('a');
            link.download = resp.Data.nome;
            link.href = `data:${resp.Data.contentType};base64,${resp.Data.bytes}`;
            link.click();
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Assembleia", resp.Message, 5000, "danger");
        }
        setBaixarLoading(false);
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }
    
    let dateFormat = moment(props.data?.dataEnvio);

    return (
        <>
            <ListCard>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.tipoDocumentoNome} {props.data?.tipoDocumentoId===3 ? ` - (${props.data?.documentoNome})` : ""}</ListCard.Text>
                        <ListCard.Title skeleton={props.skeleton}>{props.data?.descricao}</ListCard.Title>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Text>&nbsp;</ListCard.Text>
                        <ListCard.Title skeleton={props.skeleton}>{dateFormat.format("DD/MM/yyyy HH:mm")}</ListCard.Title>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button 
                        variant="light" 
                        className={`p-1 me-2 ${skeletonButton}`} 
                        onClick={()=>{ handleBaixarDocumento(); }}
                        disabled={baixarLoading}
                    >
                        <IconSVG path={IconDonwload} width={32} height={32} className="me-2"/> Baixar
                    </Button>
                </ListCard.Action>
            </ListCard>
        </>
    )
}

