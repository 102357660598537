
type TabsProps = {
    options: TabsPropsOption[];
    active?: any;
    onChange?: (value: any) => void;
}

type TabsPropsOption = {
    value: any;
    label: any;
}

export default function Tabs({options, active, onChange}: TabsProps){

    function handleClick(value: any){
        if(onChange){
            onChange(value);
        }
    }

    return (
        <div className="d-flex flex-row" style={{gap: 24}}>
            {options.map((option, index)=>{
                return (
                    <div
                        key={index}
                        className={`tab-option pb-1 fw-medium fs-6 ${active && active===option.value ? "active" : ""}`}
                        onClick={()=>{ handleClick(option.value); }}
                    >
                        {option.label}
                    </div>
                )
            })}
        </div>
    )
}