import { ApiResponseEntity } from "./ApiResponseEntity";

export type LoginPostResponseEntity = Omit<ApiResponseEntity, 'Data'> & {
    Data?: LoginEntity;
};

export type LoginRecuperarSenhaResponseEntity = Omit<ApiResponseEntity, 'Data'> & {
    Data?: any;
};

export type LoginEntity = {
    hashLogin: string;
    welcomeMessage: string;
    superUser: boolean;
    usuario: LoginUsuarioEntity;
    menus?: LoginMenuEntity[];
}

export type LoginUsuarioEntity = {
    id: number;
    nome: string;
    email: string;
    empresaId: number;
    grupoId?: number;
};

export enum LoginMenuEntity {
    gerenciador = 1000,
    alertas = 1002,
    acessos = 1003,
    grupos = 1004,
    usuarios = 1005,
    configuracoes = 1006,
    assembleias = 1007,
    homologacoes = 1008,
}