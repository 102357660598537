import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import moment from 'moment';

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ListCard from "../ListCard";
import { queryClient } from "../../services/QueryClientService";
import AssembleiaService from "../../services/AssembleiaService";
import IconSVG from "../IconSVG";
import { ReactComponent as IconFechar } from "../../icons/fechar.svg";
import { ReactComponent as IconEditar } from "../../icons/editar.svg";

type AssembleiasCardPropsContent = {
    data: any;
    permission: boolean;
    skeleton?: never;
}

type AssembleiasCardPropsSkeleton = {
    data?: never;
    permission?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type AssembleiasCardProps = AssembleiasCardPropsContent | AssembleiasCardPropsSkeleton;

export default function AssembleiasCard(props: AssembleiasCardProps){

    const assembleiaService = new AssembleiaService();
    
    const navigate = useNavigate();
    const { handleLogout } = useAuth();
    const { handleToast } = useToast();
    
    const [removeModal, setRemoveModal] = useState(false);
    const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
    const [removed, setRemoved] = useState(false);
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/assembleia/"+props.data.assembleiaId);
        }
    }

    function handleClickEdit(e: any){
        e.stopPropagation();
        if(!props.skeleton && props.data){
            navigate("/assembleiaFormulario/"+props.data.assembleiaId);
        }
    }

    function handleClickRemove(e: any){
        e.stopPropagation();
        setRemoveModal(true);
    }

    async function handleRemove(e : any){
        if(!props.skeleton && props.data){
            setRemoveLoadingModal(true);
            let resp = await assembleiaService.delete(e);
            if(resp.Result===1){
                queryClient.refetchQueries('assembleias');
                queryClient.invalidateQueries(['assembleia', e]);
                setRemoved(true);
            }else{
                if(resp.Result===99){
                    handleLogout();
                    navigate("/");
                }
                handleToast("Assembleia", resp.Message, 5000, "danger");
            }
            setRemoveModal(false);
            setRemoveLoadingModal(false);
        }
    }

    function handleCloseModal(){
        if(!removeLoadingModal){
            setRemoveModal(false);
        }
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    if(removed){
        return <></>
    }

    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome da empresa</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.clientes.join(", ") ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Número do processo</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.numeroProcesso ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Administrador Judicial</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.administradorJudicial ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Data</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{moment(props.data?.dataProximoAto).format("DD/MM/yyyy HH:mm")}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    {props.permission === true &&(
                        <>
                            <Button variant="light" className={`p-1 me-2 ${skeletonButton}`} title="Editar" onClick={handleClickEdit}>
                                <IconSVG path={IconEditar} width={32} height={32}/>
                            </Button>
                            <Button variant="light" className={`p-1 me-2 ${skeletonButton}`} title="Remover" onClick={handleClickRemove}>
                                <IconSVG path={IconFechar} width={32} height={32}/>
                            </Button>
                        </>
                    )}
                    
                    <Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`}/>
                </ListCard.Action>
            </ListCard>

            <Modal show={removeModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remover Assembleia</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    { !removeLoadingModal ? (
                        "Deseja realmente remover esta assembleia?"
                    ) : (
                        <Spinner />
                    )}
                </Modal.Body>
                { !removeLoadingModal && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
                        <Button variant="danger" onClick={() => {handleRemove(props.data?.assembleiaId)}}>Remover</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}