import { ReactNode } from "react";
import { Row } from "react-bootstrap";

type BodyProps = {
    children: ReactNode;
}

export default function Body({children}: BodyProps){
	return(
		<Row className="flex-column flex-md-row" style={{minHeight: "100dvh"}}>
			{ children }
		</Row>
	)
}