import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, Spinner, Row, Col, FloatingLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Icon from "@mdi/react";
import AsyncSelect from "react-select/async";
import { mdiChevronLeft, mdiInformationOutline } from "@mdi/js";
import Select from "react-select";

import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { AsyncFloatingSelectStyle, AsyncSelectStyle, AsyncSelectTipoCredor } from "../../config/defines";
import Layout from "../../components/Layout";
import IconSVG from "../../components/IconSVG";
import ListCard from "../../components/ListCard";
import { ReactComponent as IconMais } from "../../icons/mais.svg";
import { ReactComponent as IconFechar } from "../../icons/fechar.svg";
import { ReactComponent as IconCheck } from "../../icons/check.svg";
import CredenciamentoService from "../../services/CredenciamentoService";
import { CredenciamentoPostResponseEntity, CredenciamentoPostRequestEntity } from "../../entities/CredenciamentoEntity";
import AssembleiaService from "../../services/AssembleiaService";
import { AssembleiaGetClientesResponseEntity } from "../../entities/AssembleiaEntity";

const screenConfig = {
	toastTitle: "Credenciamento",
	queryId: "credenciamento",
	routeBack: "/assembleias",
	routeSubmit: "/assembleiaCredenciamentoFormulario/",
};

type Credor = {
	id: string;
	nome: string;
	classe: string;
	classeId: number;
	valor: string;
	empresa: string;
	empresaId: number;
	tipo: any;
	arquivos: CredorArquivo[];
	credorRepresentadoId: number | undefined;
	assembleiaClienteId: number;
};

type CredorArquivo = {
	loading: boolean;
	value: string;
	arquivo: string;
	mimeType: string;
	nome: string;
	tipo: string;
	tipoId: number;
	toolTip: string;
};

type ProcuradorArquivo = {
	loading: boolean;
	value: string;
	arquivo: string;
	mimeType: string;
	nome: string;
	tipo: string;
	tipoId: number;
};

const formProcuradorArquivoInicial: ProcuradorArquivo = {
	loading: false,
	value: "",
	arquivo: "",
	mimeType: "",
	nome: "",
	tipo: "OAB ou Documento de identidade",
	tipoId: 7,
};

const formFiltroClassesSelect = [
	{ label: "Trabalhista", value: "1" },
	{ label: "Garantia Real", value: "2" },
	{ label: "Quirografária", value: "3" },
	{ label: "ME ou EPP", value: "4" },
];

const formFiltroTiposSelect = [
	{ label: "Pessoa Física", value: "1" },
	{ label: "Pessoa Jurídica", value: "2" },
	{ label: "Sindicato", value: "3" },
];

const credorArquivoOutro = { loading: false, value: "", arquivo: "", mimeType: "", nome: "", tipo: "Outro", tipoId: 1, toolTip: "Outro documento que você julga necessário" };
const credorArquivoProcuracao = {
	loading: false,
	value: "",
	arquivo: "",
	mimeType: "",
	nome: "",
	tipo: "Procuração",
	tipoId: 2,
	toolTip: "Procuração contendo poderes específicos para participação na Assembleia-Geral de credores",
};
const credorArquivoRG = { loading: false, value: "", arquivo: "", mimeType: "", nome: "", tipo: "Documento de identidade", tipoId: 3, toolTip: "Documento de identificação oficial do sócio com foto" };
const credorArquivoContratoSocial = { loading: false, value: "", arquivo: "", mimeType: "", nome: "", tipo: "Contrato Social", tipoId: 4, toolTip: "Contrato Social da empresa credora" };
const credorArquivoVinculoSindical = {
	loading: false,
	value: "",
	arquivo: "",
	mimeType: "",
	nome: "",
	tipo: "Comprovante do vínculo sindical",
	tipoId: 5,
	toolTip: "Documentos que comprovem o vínculo sindical com os representados",
};
const credorArquivoAtaRepresentante = {
	loading: false,
	value: "",
	arquivo: "",
	mimeType: "",
	nome: "",
	tipo: "Ata de eleição do representante",
	tipoId: 6,
	toolTip: "Ata de eleição do representante sindical",
};

export default function AssembleiaCredenciamentoFormulario() {
	const credenciamentoService = new CredenciamentoService();
	const assembleiaService = new AssembleiaService();

	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { user, handleLogout } = useAuth();

	const [formSaving, setFormSaving] = useState(false);
	const [formSavingMessage, setFormSavingMessage] = useState("");
	const [formStage, setFormStage] = useState(0);

	const [formFiltroClasse, setFormFiltroClasse] = useState<{ label: string; value: string }>();
	const [formClienteOptions, setFormClienteOptions] = useState<{ label: string; value: string }[]>();
	const [formFiltroCliente, setFormFiltroCliente] = useState<{ label: string; value: string }>();
	const [formCredores, setFormCredores] = useState<Credor[]>([]);
	const [formProcuradorArquivo, setFormProcuradorArquivo] = useState<ProcuradorArquivo>(formProcuradorArquivoInicial);

	useQuery(["clientes", id], () => fetchDataClientes(), { refetchOnWindowFocus: false });

	// const mutationProcurador = useMutation(mutateProcuradorData, { onSuccess: mutateProcuradorSuccess });
	// const mutationDocumentos = useMutation(mutateDocumentosData, { onSuccess: mutateDocumentosSuccess });
	// const mutation = useMutation(mutateData);

	async function fetchDataClientes() {
		let resp: AssembleiaGetClientesResponseEntity = await assembleiaService.getClientes(Number(id));
		if (resp.Result === 1 && resp.Data) {
			setFormClienteOptions(
				resp.Data.clientes.map((item) => {
					return { label: `${item.clienteNome} - ${item.cnpj}`, value: String(item.assembleiaClienteId) };
				})
			);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast("Clientes", resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	/* POST */
	async function mutateData(data: CredenciamentoPostRequestEntity) {
		let resp = await credenciamentoService.post(data);
		return resp;
	}

	function mutateSuccess(resps: CredenciamentoPostResponseEntity[]) {
		let status = true;
		resps.forEach((resp) => {
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
				status = false;
			}
		});
		if (status) {
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			navigate("/assembleiaCredenciada/" + id);
			setFormStage(4);
		}
		handleCancel();
	}

	async function handleSave() {
		setFormSaving(true);

		// let promises: any = [];
		let status = true;

		let assembleiaClienteId: number | null = null;
		for (let i in formCredores) {
			let credor = formCredores[i];
			let data: CredenciamentoPostRequestEntity = {
				assembleiaId: Number(id),
				documentoProcurador: null,
				credores: [
					{
						assembleiaClienteId: Number(credor.assembleiaClienteId),
						credorId: Number(credor.id),
						documentos: credor.arquivos
							.filter((arquivo) => arquivo.arquivo && arquivo.arquivo.length > 0)
							.map((arquivo) => {
								return {
									tipoArquivoId: arquivo.tipoId,
									nome: arquivo.nome,
									contentType: arquivo.mimeType,
									bytes: arquivo.arquivo,
								};
							}),
					},
				],
			};
			if (assembleiaClienteId != Number(credor.assembleiaClienteId)) {
				assembleiaClienteId = Number(credor.assembleiaClienteId);
				data["documentoProcurador"] = {
					bytes: formProcuradorArquivo.arquivo,
					nome: formProcuradorArquivo.nome,
					contentType: formProcuradorArquivo.mimeType,
					tipoArquivoId: formProcuradorArquivo.tipoId,
				};
			}
			setFormSavingMessage(`${Number(i) + 1}/${formCredores.length}`);
			let resp = await credenciamentoService.post(data);
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
				status = false;
			}
			// })
		}

		if (status) {
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			navigate("/assembleiaCredenciada/" + id);
		}
		// Promise.all(promises).then(mutateSuccess);

		setFormSaving(false);
	}

	function handleCancel() {
		setFormRefetching(true);
		formStatus = false;
		setFormSaving(false);
	}

	/* FORMULARIO */
	function handleVoltar() {
		navigate(screenConfig.routeBack);
	}

	function handleCredorSelect(item: any) {
		let credor: Credor = {
			id: item.value,
			nome: item.nome,
			classe: item.classe,
			valor: item.total,
			empresa: item.empresa,
			empresaId: item.empresaId,
			classeId: item.classeId,
			tipo: item.tipo,
			arquivos: [],
			credorRepresentadoId: undefined,
			assembleiaClienteId: item.assembleiaClienteId,
		};
		setFormCredores((p) => [...p, credor]);
	}

	function handleFormCredorArquivo(event: any, id: string, index: number) {
		if (event.target.files[0].size > 10485760) {
			handleToast(screenConfig.toastTitle, "Tamanho do arquivo excede o máximo de 10MB", 5000, "danger");
			return;
		}
		setFormCredores((p) =>
			p.map((item) => {
				let arquivos = [...item.arquivos];
				if (item.id === id) {
					arquivos.map((arquivoItem, arquivoIndex) => {
						return { ...arquivoItem, loading: arquivoIndex === index };
					});
				}
				return { ...item, arquivos: arquivos };
			})
		);
		let reader = new FileReader();
		let file = event.target.files[0];
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			if (typeof reader.result === "string") {
				let arquivo = {
					value: event.target.value,
					arquivo: reader.result,
					mimeType: file.type,
					nome: file.name,
					loading: false,
				};
				setFormCredores((p) =>
					p.map((item) => {
						let arquivos = [...item.arquivos];
						if (item.id === id) {
							arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
								return arquivoIndex === index ? { ...arquivoItem, ...arquivo } : arquivoItem;
							});
							if (index === arquivos.length - 1) {
								arquivos.push(credorArquivoOutro);
							}
						}
						return { ...item, arquivos: arquivos };
					})
				);
			}
		};
	}

	function handleFormProcuradorArquivo(event: any, tipo: string) {
		if (event.target.files[0].size > 10485760) {
			handleToast(screenConfig.toastTitle, "Tamanho do arquivo excede o máximo de 10MB", 5000, "danger");
			return;
		}
		let reader = new FileReader();
		let file = event.target.files[0];
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			if (typeof reader.result === "string") {
				let arquivo = {
					value: event.target.value,
					arquivo: reader.result,
					mimeType: file.type,
					nome: file.name,
					loading: false,
					tipoId: 7,
					tipo: "OAB ou Documento de identidade",
				};
				setFormProcuradorArquivo(arquivo);
			}
		};
	}

	const handleCredoresOptions = (inputValue: string, callback: (options: any[]) => void) => {
		if (inputValue.length >= 0) {
			credenciamentoService.getCredores({ assembleiaId: id ?? "", filtro: inputValue, assembleiaClienteId: formFiltroCliente?.value, classeId: formFiltroClasse?.value }).then((resp) => {
				let callbackData: any = [];
				if (resp.Result === 1 && resp.Data) {
					resp.Data.credores.forEach((credor) => {
						callbackData.push({
							label: `${credor.credorNome} - ${credor.credorTotal}`,
							value: credor.credorId,
							nome: credor.credorNome,
							total: credor.credorTotal,
							empresa: credor.clienteNome,
							classe: credor.classeNome,
							empresaId: credor.clienteId,
							classeId: credor.classeId,
							assembleiaClienteId: credor.assembleiaClienteId,
						});
					});
				} else {
					if (resp.Result === 99) {
						handleLogout();
						navigate("/");
					}
					handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
				}
				callback(callbackData);
			});
		}
	};

	function handleCredorTipo(id: string, value: any) {
		let arquivos: CredorArquivo[] = [];
		if (value.value === "1") {
			arquivos.push(credorArquivoProcuracao, credorArquivoRG, credorArquivoOutro);
		} else if (value.value === "2") {
			arquivos.push(credorArquivoProcuracao, credorArquivoContratoSocial, credorArquivoRG, credorArquivoOutro);
		} else {
			arquivos.push(credorArquivoProcuracao, credorArquivoVinculoSindical, credorArquivoAtaRepresentante, credorArquivoOutro);
		}
		setFormCredores((credores) => {
			return credores.map((credor) => {
				return credor.id === id ? { ...credor, tipo: value, arquivos: arquivos } : credor;
			});
		});
	}

	let formStatus = true;
	formCredores.forEach((credor) => {
		if (credor.tipo !== undefined) {
			formStatus = false;
		} else {
			formStatus = true;
		}
	});
	let credorStatus = true;
	if (formCredores.every((credor) => credor.arquivos.some((arquivo) => arquivo.nome !== ""))) {
		credorStatus = false;
	} else {
		credorStatus = true;
	}

	if (formProcuradorArquivo.arquivo === "") {
		formStatus = true;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center">
				<IconSVG path={IconCheck} width={24} height={24} className="me-1" /> Formulário de Credenciamento
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} /> Voltar
					</Button>
				</div>
			</h5>

			{/* CREDORES */}
			{formStage === 0 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<h5 className="mb-4">Credenciar credores</h5>

							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" controlId="enviarPara">
										<AsyncSelect
											placeholder={"Buscar"}
											value={null}
											filterOption={(option) => {
												return formCredores.findIndex((credor) => {
													return credor.id === option.value;
												}) >= 0
													? false
													: true;
											}}
											noOptionsMessage={(v) => {
												return v.inputValue.length === 0 ? "Digite para buscar a opção desejada" : "Nenhuma opção disponivel";
											}}
											loadingMessage={() => {
												return (
													<>
														<Spinner size="sm" /> Carregando opções
													</>
												);
											}}
											loadOptions={handleCredoresOptions}
											onChange={(value: any) => {
												handleCredorSelect(value);
											}}
											styles={AsyncSelectStyle}
										/>
									</Form.Group>
								</Col>
								<Col md={3}>
									<FloatingLabel controlId="classe" label="Classe" className="mb-3 floating-select">
										<Select
											placeholder={"Selecione a classe"}
											value={formFiltroClasse}
											noOptionsMessage={() => {
												return "Nenhuma opção disponivel";
											}}
											options={formFiltroClassesSelect}
											styles={AsyncFloatingSelectStyle}
											onChange={(value) => {
												setFormFiltroClasse(value ? value : undefined);
											}}
											isClearable={true}
										/>
									</FloatingLabel>
								</Col>
								<Col md={3}>
									<FloatingLabel controlId="empresa" label="Empresa" className="mb-3 floating-select">
										<Select
											placeholder={"Selecione a empresa"}
											value={formFiltroCliente}
											noOptionsMessage={() => {
												return "Nenhuma opção disponivel";
											}}
											options={formClienteOptions}
											styles={AsyncFloatingSelectStyle}
											onChange={(value) => {
												setFormFiltroCliente(value ? value : undefined);
											}}
											isClearable={true}
										/>
									</FloatingLabel>
								</Col>
							</Row>

							<div className="mb-3">
								{formCredores.length === 0 ? (
									<div className="text-center p-4 d-flex justify-content-center align-items-center mt-2 text-muted">
										Nenhum credor selecionado, você deve buscar o(s) credor(es) que deseja representar.
									</div>
								) : (
									<>
										{formCredores
											.filter((x) => {
												return formFiltroCliente?.value ? x.assembleiaClienteId === Number(formFiltroCliente?.value) : true;
											})
											.filter((x) => {
												return formFiltroClasse?.value ? x.classeId === Number(formFiltroClasse?.value) : true;
											})
											.map((credor, credorIndex) => {
												return (
													<ListCard key={credorIndex}>
														<ListCard.Content>
															<ListCard.Item>
																<ListCard.Title>Credor</ListCard.Title>
																<ListCard.Text>{credor.nome}</ListCard.Text>
															</ListCard.Item>
															<ListCard.Item>
																<ListCard.Title>Classe</ListCard.Title>
																<ListCard.Text>{credor.classe}</ListCard.Text>
															</ListCard.Item>
															<ListCard.Item>
																<ListCard.Title>Empresa</ListCard.Title>
																<ListCard.Text>{credor.empresa}</ListCard.Text>
															</ListCard.Item>
															<ListCard.Item>
																<ListCard.Title>Valor</ListCard.Title>
																<ListCard.Text>{credor.valor}</ListCard.Text>
															</ListCard.Item>
														</ListCard.Content>
														<ListCard.Action>
															<Button
																variant="light"
																className={`p-1 me-2`}
																onClick={() => {
																	setFormCredores((p) => p.filter((item, itemIndex) => credorIndex !== itemIndex));
																}}
																disabled={formSaving}
															>
																<IconSVG path={IconFechar} width={32} height={32} className="text-danger" />
															</Button>
														</ListCard.Action>
													</ListCard>
												);
											})}
									</>
								)}
							</div>

							<Button
								className="me-2 p-3"
								variant="primary"
								type="button"
								onClick={() => {
									setFormStage(1);
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								{formSaving && <Spinner animation="border" size="sm" className="me-2" />} Próximo
							</Button>
							<Button
								className="me-2 p-3"
								variant="light"
								type="button"
								onClick={() => {
									handleVoltar();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}

			{/* DOCUMENTOS */}
			{formStage === 1 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<h5 className="mb-4">
								Adicionar arquivos
								<div className="mt-2 fs-6 fw-light">
									São aceitos apenas arquivos em formato <b className="fw-bold">PDF</b>
									<br />
									Tamanho máximo por arquivo: <b className="fw-bold">10MB</b>
								</div>
							</h5>

							<h6 className="mb-2 mt-2 fs-4">Credores</h6>
							{formCredores.map((credor, credorIndex) => {
								return (
									<div key={credorIndex}>
										<Col md="auto" className="fs-6 fw-lighter pt-2 pb-2 d-flex align-items-center">
											<div className="mb-1 fs-6 fw-bold">{credor.nome} - </div>
											<Col xl={3.5}>
												<FloatingLabel label="" className="ms-2">
													<Select
														placeholder={"Selecione o tipo do credor"}
														value={credor.tipo}
														options={formFiltroTiposSelect}
														styles={AsyncSelectTipoCredor}
														onChange={(value) => {
															handleCredorTipo(credor.id, value);
														}}
														isClearable={false}
														isSearchable={false}
													/>
												</FloatingLabel>
											</Col>
										</Col>
										<Form.Group className="mb-4 d-flex" style={{ gap: 24 }}>
											{credor.arquivos.map((arquivo, arquivoIndex) => {
												return (
													<Form.Label className="mb-0" key={arquivoIndex}>
														<div className="mt-2">
															<div
																className={`img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-around form-control p-2 fw-bold`}
																style={{ width: 160, height: 180, borderStyle: "dashed" }}
															>
																{arquivo.loading ? (
																	<Spinner className="mb-2" />
																) : (
																	<>
																		{arquivo.nome ? (
																			<span className="text-muted fw-normal" style={{ wordBreak: "break-all", maxHeight: "100%", overflow: "hidden" }}>
																				{arquivo.nome}
																			</span>
																		) : (
																			<IconSVG path={IconMais} width={46} height={46} />
																		)}
																		<div>
																			{arquivo.tipo}
																			<OverlayTrigger placement={"bottom"} overlay={<Tooltip>{arquivo.toolTip}</Tooltip>}>
																				<Icon path={mdiInformationOutline} size={0.9} className="ms-1" />
																			</OverlayTrigger>
																		</div>
																	</>
																)}
															</div>
														</div>
														<Form.Control
															accept="application/pdf"
															className="d-none"
															type="file"
															placeholder="arquivo"
															value={arquivo.value}
															onChange={(e) => {
																handleFormCredorArquivo(e, credor.id, arquivoIndex);
															}}
														/>
													</Form.Label>
												);
											})}
										</Form.Group>
									</div>
								);
							})}

							<h6 className="mb-3 mt-2 fs-4">Procurador</h6>

							<div className="mb-1 fs-6 fw-bold">{user?.usuario.nome}</div>

							<Form.Group className="mb-4 d-flex" style={{ gap: 24 }}>
								<Form.Label className="mb-0">
									<div className="mt-2">
										<div
											className={`img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-around form-control p-2 fw-bold`}
											style={{ width: 160, height: 180, borderStyle: "dashed" }}
										>
											{formProcuradorArquivo.loading ? (
												<Spinner className="mb-2" />
											) : (
												<>
													{formProcuradorArquivo.nome ? (
														<span className="mb-2 text-muted fw-normal" style={{ wordBreak: "break-all", maxHeight: "100%", overflow: "hidden" }}>
															{formProcuradorArquivo.nome}
														</span>
													) : (
														<IconSVG path={IconMais} width={46} height={46} className="mb-4" />
													)}
													<div>{formProcuradorArquivo.tipo}</div>
												</>
											)}
										</div>
									</div>
									<Form.Control
										accept="application/pdf"
										className="d-none"
										type="file"
										placeholder="arquivo"
										value={formProcuradorArquivo.value}
										onChange={(e) => {
											handleFormProcuradorArquivo(e, formProcuradorArquivo.tipo);
										}}
									/>
								</Form.Label>
							</Form.Group>

							<Button
								className="me-2 p-3"
								variant="primary"
								type="button"
								onClick={() => {
									handleSave();
								}}
								style={{ width: 180 }}
								disabled={formStatus !== true && credorStatus === false ? false : true}
							>
								{formSaving ? (
									<>
										<Spinner animation="border" size="sm" className="me-2" /> Salvando {formSavingMessage}
									</>
								) : (
									"Salvar"
								)}
							</Button>
							<Button
								className="me-2 p-3"
								variant="light"
								type="button"
								onClick={() => {
									handleVoltar();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}
		</Layout>
	);
}

function setFormRefetching(arg0: boolean) {
	throw new Error("Function not implemented.");
}
