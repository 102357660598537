import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, FloatingLabel, Form, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import { useMutation, useQuery } from "react-query";
import AsyncSelect from 'react-select/async';
import moment, { Moment } from 'moment';

import Layout from "../../components/Layout";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AlertaEntity, AlertaGetResponseEntity, AlertaPostResponseEntity } from "../../entities/AlertaEntity";
import { alertaAutocomplete, alertaGet, alertaPost } from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { AsyncFloatingSelectStyle } from "../../config/defines";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconAlertas } from "../../icons/alertas.svg";

export default function AlertaFormulario(){

    const navigate = useNavigate();
	const { id } = useParams();
    const { handleToast } = useToast();
    const { handleLogout } = useAuth();

    const [formStatus, setFormStatus] = useState(id ? false : true);
    const [formSaving, setFormSaving] = useState(false);
    const [formRefetching, setFormRefetching] = useState(false);
    const [formPessoaId, setFormPessoaId] = useState<number>();
    const [formTitulo, setFormTitulo] = useState("");
    const [formTexto, setFormTexto] = useState("");
    const [formNavegacao, setFormNavegacao] = useState<{label: string; value: number}>();
    const [formSituacao, setFormSituacao] = useState(null);
    const [formTipo, setFormTipo] = useState<boolean>(false);
    const [formPessoasId, setFormPessoasId] = useState<{label: string; value: number}[]>();
    const [formData, setFormData] = useState<Moment>(moment());

    const [formNavegacaoOptions, setFormNavegacaoOptions] = useState<{label: string; value: number}[]>();
    
    const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>(['alerta', id], () => fetchData(id), {enabled: id !== undefined && formNavegacaoOptions !== undefined});
    const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

    // useQuery('navegacao', () => fetchDataNavegacao());

    useEffect(()=>{
        if(formRefetching){
            refetch();
        }
    // eslint-disable-next-line
    },[formRefetching]);

    async function fetchData(id: any){
        if(formStatus && !formRefetching){
            return false;
        }

        let resp: AlertaGetResponseEntity = await alertaGet(id);
        if(resp.Result===1 && resp.Data){

            setFormPessoaId(resp.Data.pessoaId);
            setFormTitulo(resp.Data.titulo);
            setFormTexto(resp.Data.texto);

            let navegacao = undefined;
            if(resp.Data?.navegacao){
                let navegacaoJson = JSON.parse(`${resp.Data?.navegacao}`);
                navegacao = formNavegacaoOptions?.find((item)=> item.value===navegacaoJson.id );
            }
            setFormNavegacao(navegacao);

            setFormSituacao(null);
            setFormTipo(resp.Data.tipo===0 ? false : true);
            if(resp.Data.pessoaId && resp.Data.pessoaNome){
                setFormPessoasId([{label: `${resp.Data.pessoaNome}`, value: resp.Data.pessoaId ?? 0}]);
            }
            setFormData(moment(resp.Data.data));

        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Alerta", resp.Message, 5000, "warning");
            handleVoltar();
        }
        
        setFormRefetching(false);

        return true;
    }

    async function mutateData(data: AlertaEntity) {
        let resp = await alertaPost(data);
        return resp;
    }

    function mutateSuccess(resp: AlertaPostResponseEntity){
        if(resp.Result===1){
            queryClient.invalidateQueries(['alerta', id]);
            handleToast("Alerta", "Informações salvas com sucesso!", 5000);
            if(id){
                navigate("/alertaFormulario/"+resp.Data);    
            }else{
                navigate("/alertas");
            }
        }else{
            if(resp.Result===99){
                handleLogout();
                navigate("/");
            }
            handleToast("Alerta", resp.Message, 5000, "danger");
        }
        handleCancel();
    }

    // async function fetchDataNavegacao(){
    //     let resp: AlertaGetNavegacaoResponseEntity = await alertaGetNavegacao(aplicativo);
    //     if(resp.Result===1 && resp.Data){
            
    //         setFormNavegacaoOptions(resp.Data.map((item)=>{ return { label: `${item.servicoNome}`, value: item.servicoId } }));

    //     }else{
    //         if(resp.Result===99){
    //             handleLogout();
    //             navigate("/");
    //         }
    //         handleToast("Usuário - Empresas", resp.Message, 5000, "warning");
    //         handleVoltar();
    //     }

    //     return true;
    // }

    function handleVoltar(){
        navigate("/alertas");
    }

    async function handleSave(){
        setFormSaving(true);

        const data: AlertaEntity = {
            alertaId: id ? Number(id) : undefined,
            pessoaId: formPessoaId,
            titulo: formTitulo,
            texto: formTexto,
            navegacao: formNavegacao?.value,
            situacao: formSituacao,
            tipo: formTipo ? 1 : 0,
            pessoasId: formPessoasId ? formPessoasId.map((item)=>{ return item.value }) : undefined,
        }

        mutation.mutate(data);
    }

    function handleCancel(){
        setFormRefetching(true);
        setFormStatus(false);
        setFormSaving(false);
    }

    const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
        alertaAutocomplete(inputValue).then((resp)=>{
            let callbackData = [];
            if(resp.Result===1 && resp.Data){
                for(let i in resp.Data){
                    callbackData.push({label: `${resp.Data[i].nome}${resp.Data[i].carteira ? ` - ${resp.Data[i].carteira}` : ""}`, value: resp.Data[i].pessoaId})
                }
            }else{
                if(resp.Result===99){
                    handleLogout();
                    navigate("/");
                }
                handleToast("Alerta", resp.Message, 5000, "danger");
            }
            callback(callbackData);
        });
    };

    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <IconSVG path={IconAlertas} width={24} height={24} className="me-1"/> Formulário de Alerta
                { (isLoading || isFetching || isRefetching) && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
                <div className="float-right ms-auto" style={{marginTop: -10, marginBottom: -10}}>
                    <Button className="d-flex" variant="dark" onClick={handleVoltar}><Icon path={mdiChevronLeft} size={1}/></Button>
                </div>
            </h5>
            
            <Card className="mb-4">
                <Card.Body>
                    
                    <Form>
                        <FloatingLabel controlId="titulo" label="Título" className="mb-3">
                            <Form.Control type="text" placeholder="Informe aqui o título" value={formTitulo} onChange={(event)=>{ setFormTitulo(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel>
                        <FloatingLabel controlId="texto" label="Texto" className="mb-3">
                            <Form.Control type="text" placeholder="Informe aqui o texto" value={formTexto} onChange={(event)=>{ setFormTexto(event.target.value); }} disabled={!formStatus}/>
                        </FloatingLabel>
                        {/* <Form.Group className="mb-3" controlId="navegarPara">
                            <Form.Label>Navegar para</Form.Label>
                            <Select
                                placeholder={"Selecione a navegação"}
                                defaultValue={formNavegacao}
                                value={formNavegacao ?? ''}
                                options={formNavegacaoOptions}
                                noOptionsMessage={()=>{ return "Nenhuma opção disponivel" }}
                                onChange={(value)=>{ if(value && typeof value != "string") setFormNavegacao(value); }}
                                isDisabled={!formStatus}
                                styles={AsyncSelectStyle}
                            />
                        </Form.Group> */}
                        <FloatingLabel controlId="enviarPara" label="Enviar para" className="mb-3 floating-select">
                            <AsyncSelect
                                placeholder={"Todos"}
                                value={formPessoasId}
                                noOptionsMessage={(v)=>{ return v.inputValue.length===0 ? "Digite para buscar a opção desejada" : "Nenhuma opção disponivel" }}
                                loadingMessage={()=>{ return <><Spinner size="sm"/> Carregando opções</> }}
                                loadOptions={loadOptions}
                                isMulti={true}
                                isDisabled={!formStatus || !!id}
                                onChange={(value : any)=>{ setFormPessoasId(value); }}
                                styles={AsyncFloatingSelectStyle}
                            />
                        </FloatingLabel>
                        <Form.Group className="mb-3" controlId="enviarPush">
                            <Form.Label>Enviar Push</Form.Label>
                            <Form.Check type="switch" checked={formTipo} onChange={()=>{ setFormTipo(!formTipo) }} disabled={!formStatus || !!id}/>
                        </Form.Group>
                        { !!id && (
                            <Form.Group className="mb-3" controlId="data">
                                <Form.Label>Data</Form.Label>
                                <Form.Control type="text" placeholder="Informe aqui o texto" value={formData.format("DD/MM/yyyy HH:mm")} disabled={true}/>
                            </Form.Group>
                        )}
                        
                        {!formStatus ? (
                            <Button className="me-2 p-3" variant="dark" type="button" onClick={()=>{ setFormStatus(true); }} style={{width: 180}}>Editar Informações</Button>
                        ) : (
                            <>
                                <Button className="me-2 p-3" variant="primary" type="button" onClick={handleSave} style={{width: 180}} disabled={formSaving}>
                                    {formSaving ? (
                                        <><Spinner animation="border" size="sm" className="me-2"/> Salvando</>
                                    ):(
                                        "Salvar Informações"
                                    )}
                                </Button>
                                {id && (<Button className="me-2 p-3" variant="danger" type="button" onClick={handleCancel} style={{width: 180}} disabled={formSaving}>Cancelar</Button>)}
                            </>
                        )}
                    </Form>

                </Card.Body>
            </Card>
        </Layout>
    )
}