import { useEffect, useRef, useState } from "react";
import A4BMeeting from "@a4b/a4b-meeting";
import { FloatingLabel, Spinner, Form, Image, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AssembleiaGetResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";

export default function ChamadaConvidado() {
	const assembleiaService = new AssembleiaService();

	const iframeRef = useRef<HTMLIFrameElement>(null);
	const clickMeetingRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const { handleToast } = useToast();

	const [searchParams] = useSearchParams();
	const assembleiaId = searchParams.get("assembleiaId");
	const atoId = searchParams.get("atoId");
	const chamadaId = searchParams.get("chamadaId");
	const chamadaToken = searchParams.get("chamadaToken");

	const [loading, setLoading] = useState(true);
	const [iframe, setIframe] = useState(true);
	const [formStatus, setFormStatus] = useState(1);
	const [formNome, setFormNome] = useState("");

	const { isLoading, data, refetch } = useQuery(["assembleia", assembleiaId], () => fetchData(assembleiaId), { refetchOnWindowFocus: false });

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (iframeRef.current) {
			iframeRef.current.style.height = `${window.innerHeight - 6}px`;
		}
	}, [formStatus]);

	async function fetchData(id: any) {
		let resp: AssembleiaGetResponseEntity = await assembleiaService.getPublica(id);
		if (resp.Result === 1 && resp.Data) {
			setLoading(false);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast("Chamada", resp.Message, 5000, "warning");
			handleVoltar();
		}

		return resp.Data;
	}

	function handleVoltar() {
		navigate(-1);
	}
	console.log(data);
	const ato = data?.atos.find((ato) => {
		return ato.id === Number(atoId);
	});
	const descricao = `${data?.clientes[0].clienteNome} ${data && data.clientes.length > 1 ? "e outros" : ""} - ${ato?.descricao}`;

	return (
		<div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
			{formStatus === 0 ? (
				<>
					{(loading || isLoading) && (
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column bg-white" style={{ inset: 0, zIndex: 100 }}>
							<Spinner className="mb-2" />
							Carregando
						</div>
					)}
					<div className="d-flex flex-fill">
						{iframe && (
							<div className="flex-fill" style={{ background: "#272F31" }}>
								<div className="flex-fill" ref={clickMeetingRef}>
									<iframe className="w-100" src={data?.clickmeetingUrlParticipant} ref={iframeRef} allow="camera; microphone; fullscreen; speaker; display-capture;" />
								</div>
							</div>
						)}
						<div style={{ width: iframe ? 400 : "100%" }}>
							{!isLoading && !loading && (
								<A4BMeeting
									MeetingID={String(chamadaId)}
									MeetingToken={String(chamadaToken)}
									MeetingOwner={false}
									MeetingGuest={true}
									MeetingDate={ato?.dataPrevista}
									MeetingName={descricao}
									ParticipantID={generateUUID()}
									ParticipantName={formNome}
									ChatEnabled={true}
									PollsEnabled={false}
									LockEnable={true}
									EndBack={true}
									EndBackCallback={() => {
										navigate(`/assembleia/${assembleiaId}`);
									}}
									EndCallback={() => {
										setIframe(false);
									}}
									Theme={{
										logo: {
											onWhite: `${process.env.PUBLIC_URL}/von_meeting_logo_on_white.svg`,
											onBlack: `${process.env.PUBLIC_URL}/von_meeting_logo_on_black.svg`,
										},
										primary: "#C2F94B",
										primaryText: "#272F31",
										secondary: "#272F31",
										secondaryText: "#FFFFFF",
										danger: "#EB5757",
										dangerText: "#272F31",
										warning: "#F2C94C",
										warningText: "#272F31",
										disabled: "#BDBDBD",
										disabledText: "#272F31",
										background: "#272F31",
										backgroundSidebar: "#fff",
										backgroundControl: "#3B4143",
									}}
									Video={{
										frameRate: 24,
										width: 320,
										height: 240,
									}}
									apiUrl={process.env.REACT_APP_API_BASE_URL ?? ""}
									wsUrl={process.env.REACT_APP_WS_URL ?? ""}
									turnUrl={process.env.REACT_APP_TURN_URL ?? ""}
									WebRTC={false}
								/>
							)}
						</div>
					</div>
				</>
			) : (
				<div className="d-flex justify-content-center align-items-center flex-column" style={{ width: 400 }}>
					<Image className="mb-5" src={process.env.PUBLIC_URL + "/von_meeting_logo_on_white.svg"} style={{ height: 60 }} />
					<div className="fw-bold mb-4 fs-4">{ato?.descricao}</div>
					<FloatingLabel controlId="nome" label="Seu Nome" className="mb-4 w-100">
						<Form.Control
							type="text"
							placeholder="Informe aqui o seu nome"
							value={formNome}
							onChange={(event) => {
								setFormNome(event.target.value);
							}}
						/>
					</FloatingLabel>
					<Button
						type="button"
						size="lg"
						className="w-100"
						style={{ flex: 1, borderRadius: 100 }}
						onClick={() => {
							setFormStatus(0);
						}}
						disabled={formNome.length === 0}
					>
						Entrar
					</Button>
				</div>
			)}
		</div>
	);
}

function generateUUID() {
	var d = new Date().getTime();
	var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0;
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = Math.random() * 16;
		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
}
