import { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, FloatingLabel, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import PessoaService from "../../services/PessoaService";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconCredor } from "../../icons/credor.svg";
import { ReactComponent as IconAdvogado } from "../../icons/advogado.svg";
import { ReactComponent as IconEmpresa } from "../../icons/empresa.svg";
import { ReactComponent as IconSindicato } from "../../icons/sindicato.svg";
import { ReactComponent as IconChecked } from "../../icons/checked.svg";
import { PessoaPostRequestEntity } from "../../entities/PessoaEntity";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";

export default function Login() {
	const pessoaService = new PessoaService();

	const { user, handleLogin, handleRecuperarSenha } = useAuth();
	const { clearToast, handleToast } = useToast();

	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const stageParam = searchParams.get("stage");

	const emailRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;
	const passwordRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;
	const [senhaExibir, setSenhaExibir] = useState(false);

	const [isLoading, setIsLoading] = useState(0);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [stage, setStage] = useState(token ? 3 : Number(stageParam) ?? 0);
	const [formAnimation, setFormAnimation] = useState("login-form");

	const [validacaoLoading, setValidacaoLoading] = useState(token ? true : false);
	const [validacaoMessage, setValidacaoMessage] = useState("");
	const [validacaoMessageVariant, setValidacaoMessageVariant] = useState("");

	const [recuperarSenhaLoading, setRecuperarSenhaLoading] = useState(false);
	const [recuperarSenhaResult, setRecuperarSenhaResult] = useState<number>();
	const [recuperarSenhaMessage, setRecuperarSenhaMessage] = useState("");
	const [recuperarSenhaMessageVariant, setRecuperarSenhaMessageVariant] = useState("");
	const [recuperarSenhaEmail, setRecuperarSenhaEmail] = useState("");
	const [recuperarSenhaCPF, setRecuperarSenhaCPF] = useState("");
	const [recuperarSenhaCNPJ, setRecuperarSenhaCNPJ] = useState("");

	const [formLoading, setFormLoading] = useState(false);
	const [formMessage, setFormMessage] = useState("");
	const [formResult, setFormResult] = useState<number>();
	const [formTipo, setFormTipo] = useState<number>();
	const [formEmail, setFormEmail] = useState("");
	const [formSenha, setFormSenha] = useState("");
	const [formExibirSenha, setFormExibirSenha] = useState(false);
	const [formNome, setFormNome] = useState("");
	const [formCPF, setFormCPF] = useState("");
	const [formTelefone, setFormTelefone] = useState("");
	const [formCelular, setFormCelular] = useState("");
	const [formDataNascimento, setFormDataNascimento] = useState<Moment>();

	useEffect(() => {
		clearToast();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (token) {
			handleToken(token);
		}
		// eslint-disable-next-line
	}, [token]);

	useEffect(() => {
		if (isLoading >= 2) {
			setTimeout(() => {
				setFormAnimation("recuperar-form");
			}, 1000);
		}
	}, [isLoading]);

	async function handleToken(token: string) {
		let response = await pessoaService.validateToken({ token: token });
		if (response.Result === 1) {
			setValidacaoMessageVariant("primary");
		} else {
			setValidacaoMessageVariant("danger");
		}
		setValidacaoLoading(false);
		setValidacaoMessage(response.Message);
	}

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!emailRef.current || !passwordRef.current) {
			return false;
		}

		setLoading(true);
		setMessage("");

		let response = await handleLogin(emailRef.current.value, passwordRef.current.value);
		if (response === true) {
			navigate("/dashboard");
		} else {
			setLoading(false);
			setMessage(response);
		}
	}

	function handleRecuperarSenhaForm() {
		setStage(1);
	}

	function handlePular() {
		navigate("/assembleiasPublicas/proximas");
	}

	async function handleRecuperarSenhaRecuperar() {
		if (recuperarSenhaEmail === "" || (recuperarSenhaCPF === "" && recuperarSenhaCNPJ === "")) {
			return false;
		}

		setRecuperarSenhaLoading(true);
		setRecuperarSenhaMessage("");

		let response = await handleRecuperarSenha(recuperarSenhaEmail, recuperarSenhaCPF, recuperarSenhaCNPJ);
		if (response.Result === 1) {
			setRecuperarSenhaMessageVariant("primary");
		} else {
			setRecuperarSenhaMessageVariant("danger");
		}

		setRecuperarSenhaResult(response.Result);
		setRecuperarSenhaMessage(response.Message);
		setRecuperarSenhaLoading(false);
	}

	function handleRecuperarSenhaCancelar() {
		setStage(0);
		setRecuperarSenhaResult(undefined);
		setRecuperarSenhaMessage("");
	}

	function handleValidacaoEntrar() {
		setStage(0);
		navigate("/");
	}

	async function handleSubmitCadastro(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (formTipo === undefined) {
			handleToast("Cadastro", "Selecione o tipo do usuário", 5000, "danger");
			return;
		} else {
			setFormLoading(true);
			setFormMessage("");

			const data: PessoaPostRequestEntity = {
				nome: formNome,
				email: formEmail,
				cpfCnpj: formCPF,
				dataNascimento: formDataNascimento?.toISOString() ?? "",
				telefone: formTelefone,
				celular: formCelular,
				tipoId: formTipo,
				senha: formSenha,
			};

			let response = await pessoaService.post(data);
			setFormResult(response.Result);
			setFormMessage(response.Message);
			setFormLoading(false);
		}
	}

	if (user) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}

	return (
		<Container fluid id="login-container" style={{ minHeight: "100dvh" }}>
			<div id="login-background">
				<Image
					id="login-background1"
					src={process.env.PUBLIC_URL + "/login_background1.webp"}
					style={{ objectFit: "cover", display: isLoading < 2 ? "none" : "initial" }}
					onLoad={() => {
						setIsLoading((p) => p + 1);
					}}
				/>
				<Image
					id="login-background2"
					src={process.env.PUBLIC_URL + "/login_background2.webp"}
					style={{ objectFit: "cover", display: isLoading < 2 ? "none" : "initial" }}
					onLoad={() => {
						setIsLoading((p) => p + 1);
					}}
				/>
			</div>
			<Row className="align-items-center" style={{ minHeight: "100dvh", display: isLoading < 2 ? "none" : "flex" }}>
				<Col
					xs={{ span: 10, offset: 1 }}
					sm={{ span: 9, offset: 1 }}
					md={{ span: 8, offset: 1 }}
					lg={{ span: 7, offset: 1 }}
					xl={{ span: 6, offset: 1 }}
					xxl={{ span: 4, offset: 2 }}
					className="my-5"
					style={{ maxWidth: 700 }}
				>
					<Image id="login-logo" className="mb-4" src={process.env.PUBLIC_URL + "/logo_escuro.png"} style={{ height: 130 }} />

					{/* LOGIN */}
					{stage === 0 && (
						<Form className={`${formAnimation}`} onSubmit={handleSubmit}>
							<Form.Text className="mb-1 text-white text-uppercase" as="div" style={{ fontSize: 20, fontWeight: 700, fontFamily: "Satoshi" }}>
								Acesso ao sistema von meeting
							</Form.Text>
							<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
								Informe os dados abaixo para acessar o sistema
							</Form.Text>

							<FloatingLabel controlId="email" label="E-mail" className="mb-3 input-login">
								<Form.Control type="text" placeholder="Informe aqui o seu e-mail" ref={emailRef} required />
							</FloatingLabel>

							<InputGroup className="mb-3">
								<FloatingLabel controlId="senha" label="Senha" className="input-login">
									<Form.Control type={senhaExibir ? "text" : "password"} placeholder="Informe aqui a senha" ref={passwordRef} required />
								</FloatingLabel>
								<Button
									variant="outline-primary input-login"
									onClick={() => {
										setSenhaExibir(!senhaExibir);
									}}
								>
									<Icon path={senhaExibir ? mdiEye : mdiEyeOff} size={1} />
								</Button>
							</InputGroup>

							{message && (
								<Alert variant="danger" className="text-center">
									{message}
								</Alert>
							)}

							<div className="d-flex flex-row mb-2" style={{ gap: 8 }}>
								<Button type="submit" variant="primary" size="lg" style={{ flex: 1, borderRadius: 100 }} disabled={loading}>
									{loading ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Entrando
										</>
									) : (
										"Entrar"
									)}
								</Button>

								<Button
									type="button"
									variant="secondary"
									size="lg"
									style={{ flex: 1, borderRadius: 100 }}
									onClick={() => {
										setStage(2);
									}}
									disabled={loading}
								>
									Cadastrar
								</Button>
							</div>

							<div className="d-flex flex-row mb-2" style={{ gap: 8 }}>
								<Button type="button" variant="link" size="lg" className="text-start" style={{ flex: 1, borderRadius: 100 }} onClick={handleRecuperarSenhaForm} disabled={loading}>
									Recuperar senha
								</Button>
								<div style={{ flex: 1, padding: "1rem" }}></div>
							</div>
							<div className="d-flex flex-row mb-2" style={{ gap: 8 }}>
								<Button type="button" variant="link" size="lg" className="text-start" style={{ flex: 1, borderRadius: 100 }} onClick={handlePular} disabled={loading}>
									Pular
								</Button>
								<div style={{ flex: 1, padding: "1rem" }}></div>
							</div>
						</Form>
					)}

					{/* RECUPERAR SENHA */}
					{stage === 1 && (
						<Form className={`${formAnimation}`} onSubmit={handleSubmit}>
							<Form.Text className="mb-1 text-white text-uppercase" as="div" style={{ fontSize: 20, fontWeight: 700, fontFamily: "Satoshi" }}>
								Acesso ao sistema von meeting
							</Form.Text>
							<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
								Informe os dados abaixo para recuperar o acesso ao sistema
							</Form.Text>

							{recuperarSenhaResult === 1 ? (
								<>
									<Alert variant="primary" className="text-center">
										{recuperarSenhaMessage}
									</Alert>
									<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
										Enviamos uma nova senha para <span className="text-underline">{recuperarSenhaEmail}</span>.
									</Form.Text>

									<div className="d-flex flex-row" style={{ gap: 8 }}>
										<Button
											type="button"
											variant="primary"
											size="lg"
											style={{ width: 250, borderRadius: 100 }}
											onClick={handleRecuperarSenhaCancelar}
											disabled={recuperarSenhaLoading}
										>
											Entrar
										</Button>
									</div>
								</>
							) : (
								<>
									<FloatingLabel controlId="recuperarSenhaEmail" label="E-mail" className="mb-3 input-login">
										<Form.Control
											type="email"
											placeholder="Informe aqui o e-mail"
											value={recuperarSenhaEmail}
											onChange={(event) => {
												setRecuperarSenhaEmail(event.target.value);
											}}
										/>
									</FloatingLabel>

									<FloatingLabel controlId="recuperarSenhaCPF" label="CPF" className="mb-2 input-login">
										<InputMask
											mask="999.999.999-99"
											type="text"
											className="form-control"
											placeholder="Informe aqui o CPF"
											value={recuperarSenhaCPF}
											onChange={(event) => {
												setRecuperarSenhaCPF(event.target.value);
											}}
											disabled={recuperarSenhaCNPJ !== ""}
										/>
									</FloatingLabel>

									<Form.Text className="mb-2 text-white text-center" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
										ou
									</Form.Text>

									<FloatingLabel controlId="recuperarSenhaCNPJ" label="CNPJ" className="mb-3 input-login">
										<InputMask
											mask="99.999.999/9999-99"
											type="text"
											className="form-control"
											placeholder="Informe aqui o CNPJ"
											value={recuperarSenhaCNPJ}
											onChange={(event) => {
												setRecuperarSenhaCNPJ(event.target.value);
											}}
											disabled={recuperarSenhaCPF !== ""}
										/>
									</FloatingLabel>

									{recuperarSenhaMessage && (
										<Alert variant={recuperarSenhaMessageVariant} className="text-center">
											{recuperarSenhaMessage}
										</Alert>
									)}

									<div className="d-flex flex-row" style={{ gap: 8 }}>
										<Button
											type="button"
											variant="primary"
											size="lg"
											style={{ width: 250, borderRadius: 100 }}
											onClick={handleRecuperarSenhaRecuperar}
											disabled={recuperarSenhaLoading}
										>
											{recuperarSenhaLoading ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Recuperando
												</>
											) : (
												"Recuperar"
											)}
										</Button>

										<Button
											type="button"
											variant="secondary"
											size="lg"
											style={{ width: 250, borderRadius: 100 }}
											onClick={handleRecuperarSenhaCancelar}
											disabled={recuperarSenhaLoading}
										>
											Voltar
										</Button>
									</div>
								</>
							)}
						</Form>
					)}

					{/* CADASTRAR */}
					{stage === 2 && (
						<Form className={`${formAnimation}`} onSubmit={handleSubmitCadastro} style={{ position: "relative" }}>
							<Form.Text className="mb-1 text-white text-uppercase" as="div" style={{ fontSize: 20, fontWeight: 700, fontFamily: "Satoshi" }}>
								Cadastro do sistema von meeting
							</Form.Text>
							<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
								Informe os dados abaixo para acessar o sistema
							</Form.Text>

							{formResult === 1 ? (
								<>
									<Alert variant="primary" className="text-center">
										{formMessage}
									</Alert>
									<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
										Enviamos um link para <span className="text-underline">{formEmail}</span>.<br />
										Acesse para validar o seu cadastro!
									</Form.Text>
								</>
							) : (
								<>
									<Form.Group className="mb-3 d-flex" style={{ gap: 8, flexWrap: "wrap" }}>
										<Form.Label className="mb-0" style={{ flex: 1 }}>
											<div className="mt-2">
												<div
													className={`position-relative img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-center form-control p-2  text-white`}
													style={{ minWidth: 90, height: 150, cursor: "pointer", borderColor: formTipo === 2 ? "#A9D941" : "#BDBDBD" }}
												>
													<IconSVG path={IconCredor} width={48} height={48} className="mb-3" />
													Credor
													{formTipo === 2 && <IconSVG path={IconChecked} width={30} height={30} style={{ position: "absolute", top: 16, right: 16 }} />}
												</div>
											</div>
											<Form.Control
												name="tipo"
												className="d-none"
												type="radio"
												value={2}
												onChange={(event) => {
													setFormTipo(Number(event.target.value));
												}}
											/>
										</Form.Label>
										<Form.Label className="mb-0" style={{ flex: 1 }}>
											<div className="mt-2">
												<div
													className={`position-relative img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-center form-control p-2  text-white`}
													style={{ minWidth: 90, height: 150, cursor: "pointer", borderColor: formTipo === 3 ? "#A9D941" : "#BDBDBD" }}
												>
													<IconSVG path={IconAdvogado} width={48} height={48} className="mb-3" />
													Advogado
													{formTipo === 3 && <IconSVG path={IconChecked} width={30} height={30} style={{ position: "absolute", top: 16, right: 16 }} />}
												</div>
											</div>
											<Form.Control
												name="tipo"
												className="d-none"
												type="radio"
												value={3}
												onChange={(event) => {
													setFormTipo(Number(event.target.value));
												}}
											/>
										</Form.Label>
										<Form.Label className="mb-0" style={{ flex: 1 }}>
											<div className="mt-2">
												<div
													className={`position-relative img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-center form-control p-2  text-white`}
													style={{ minWidth: 90, height: 150, cursor: "pointer", borderColor: formTipo === 4 ? "#A9D941" : "#BDBDBD" }}
												>
													<IconSVG path={IconEmpresa} width={48} height={48} className="mb-3 " />
													Empresa
													{formTipo === 4 && <IconSVG path={IconChecked} width={30} height={30} style={{ position: "absolute", top: 16, right: 16 }} />}
												</div>
											</div>
											<Form.Control
												name="tipo"
												className="d-none"
												type="radio"
												value={4}
												onChange={(event) => {
													setFormTipo(Number(event.target.value));
												}}
											/>
										</Form.Label>
										<Form.Label className="mb-0" style={{ flex: 1 }}>
											<div className="mt-2">
												<div
													className={`position-relative img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-center form-control p-2  text-white`}
													style={{ minWidth: 90, height: 150, cursor: "pointer", borderColor: formTipo === 5 ? "#A9D941" : "#BDBDBD" }}
												>
													<IconSVG path={IconSindicato} width={48} height={48} className="mb-3" />
													Sindicato
													{formTipo === 5 && <IconSVG path={IconChecked} width={30} height={30} style={{ position: "absolute", top: 16, right: 16 }} />}
												</div>
											</div>
											<Form.Control
												name="tipo"
												className="d-none"
												type="radio"
												value={5}
												onChange={(event) => {
													setFormTipo(Number(event.target.value));
												}}
											/>
										</Form.Label>
									</Form.Group>

									<FloatingLabel controlId="email" label="Email" className="mb-3 input-login">
										<Form.Control
											type="email"
											placeholder="Informe aqui o email"
											value={formEmail}
											onChange={(event) => {
												setFormEmail(event.target.value);
											}}
											required
										/>
									</FloatingLabel>

									<InputGroup className="mb-3">
										<FloatingLabel controlId="senha" label="Senha" className="input-login">
											<Form.Control
												type={formExibirSenha ? "text" : "password"}
												placeholder="Informe aqui a senha"
												value={formSenha}
												onChange={(event) => {
													setFormSenha(event.target.value);
												}}
												required
											/>
										</FloatingLabel>
										<Button
											variant="outline-primary input-login"
											onClick={() => {
												setFormExibirSenha(!formExibirSenha);
											}}
										>
											<Icon path={formExibirSenha ? mdiEye : mdiEyeOff} size={1} />
										</Button>
									</InputGroup>

									<FloatingLabel controlId="nome" label="Nome" className="mb-3 input-login">
										<Form.Control
											type="text"
											placeholder="Informe aqui o nome"
											value={formNome}
											onChange={(event) => {
												setFormNome(event.target.value);
											}}
											required
										/>
									</FloatingLabel>
									{formTipo === 4 ? (
										<FloatingLabel controlId="cnpj" label="CNPJ" className="mb-3 input-login">
											<InputMask
												mask="99.999.999/9999-99"
												type="text"
												className="form-control"
												placeholder="Informe aqui o CNPJ"
												value={formCPF}
												onChange={(event) => {
													setFormCPF(event.target.value);
												}}
												required
											/>
										</FloatingLabel>
									) : (
										<FloatingLabel controlId="cpf" label="CPF" className="mb-3 input-login">
											<InputMask
												mask="999.999.999-99"
												type="text"
												className="form-control"
												placeholder="Informe aqui o CPF"
												value={formCPF}
												onChange={(event) => {
													setFormCPF(event.target.value);
												}}
												required
											/>
										</FloatingLabel>
									)}

									<FloatingLabel controlId="telefone" label="Telefone (opcional)" className="mb-3 input-login">
										<InputMask
											mask="(99) 9999-9999"
											type="text"
											className="form-control"
											placeholder="Informe aqui o telefone"
											value={formTelefone}
											onChange={(event) => {
												setFormTelefone(event.target.value);
											}}
										/>
									</FloatingLabel>

									<FloatingLabel controlId="celular" label="Celular" className="mb-3 input-login">
										<InputMask
											mask="(99) 99999-9999"
											type="text"
											className="form-control"
											placeholder="Informe aqui o celular"
											value={formCelular}
											onChange={(event) => {
												setFormCelular(event.target.value);
											}}
										/>
									</FloatingLabel>

									<FloatingLabel controlId="dataNacimento" label="Data Nascimento" className="mb-3 input-login floating-date-picker">
										<DatePicker
											popperPlacement="top"
											calendarClassName="shadow-lg"
											weekDayClassName={() => {
												return "fw-light";
											}}
											dayClassName={(date) => {
												return `btn btn-sm ${date.toDateString() === formDataNascimento?.toDate().toDateString() ? "btn-primary" : "btn-light"} `;
											}}
											monthClassName={() => {
												return "bg-danger";
											}}
											timeClassName={() => {
												return "bg-danger";
											}}
											popperModifiers={[
												{
													name: "arrow",
													options: {
														padding: ({ popper, reference }) => ({
															right: Math.min(popper.width, reference.width) - Math.min(popper.width, reference.width) + 24,
														}),
													},
												},
											]}
											selected={formDataNascimento?.toDate()}
											onChange={(date) => {
												setFormDataNascimento(moment(date));
											}}
											dateFormat="dd/MM/yyyy"
											customInput={<InputMask mask="99/99/9999" type="text" className="form-control" min="1823-01-01" />}
										/>
									</FloatingLabel>

									{formMessage && (
										<Alert variant="danger" className="text-center">
											{formMessage}
										</Alert>
									)}

									<div className="d-flex flex-row mb-2" style={{ gap: 8 }}>
										<Button type="submit" variant="primary" size="lg" style={{ flex: 1, borderRadius: 100 }} disabled={formLoading}>
											{formLoading ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Cadastrando
												</>
											) : (
												"Cadastrar"
											)}
										</Button>

										<Button
											type="button"
											variant="secondary"
											size="lg"
											style={{ flex: 1, borderRadius: 100 }}
											onClick={() => {
												setStage(0);
											}}
											disabled={formLoading}
										>
											Voltar
										</Button>
									</div>
								</>
							)}
						</Form>
					)}

					{/* VALIDAR TOKEN */}
					{stage === 3 && (
						<Form className={`${formAnimation}`}>
							<Form.Text className="mb-1 text-white text-uppercase" as="div" style={{ fontSize: 20, fontWeight: 700, fontFamily: "Satoshi" }}>
								Validação de cadastro do sistema von meeting
							</Form.Text>
							<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
								Aguarde a validação os dados para acessar o sistema
							</Form.Text>

							{validacaoLoading ? (
								<Form.Text className="mb-4 text-white" as="div" style={{ fontSize: 20, fontFamily: "Satoshi" }}>
									<Spinner animation="border" size="sm" className="me-2" /> Validando o seu cadastro
								</Form.Text>
							) : (
								<>
									<Alert variant={validacaoMessageVariant} className="text-center">
										{validacaoMessage}
									</Alert>
									<Button type="button" variant="primary" size="lg" style={{ width: 250, borderRadius: 100 }} onClick={handleValidacaoEntrar} disabled={recuperarSenhaLoading}>
										Entrar
									</Button>
								</>
							)}
						</Form>
					)}
				</Col>
			</Row>
		</Container>
	);
}
