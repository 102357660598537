import { LoginMenuEntity } from "../entities/LoginEntity";
import { ReactComponent as IconDashboard } from "../icons/dashboard.svg";
import { ReactComponent as IconAssembleias } from "../icons/assembleias.svg";
import { ReactComponent as IconCheck } from "../icons/check.svg";
import { ReactComponent as IconAlertas } from "../icons/alertas.svg";
import { ReactComponent as IconAcessos } from "../icons/acessos.svg";
import { ReactComponent as IconConfig } from "../icons/configuracoes.svg";

export const screens = [
    {
        menu: null,
        name: 'Dashboard',
        description: '',
        icon: IconDashboard,
        route: '/dashboard',
        showSidebar: true,
        showDashboard: false,
    },
    {
        menu: LoginMenuEntity.assembleias,
        name: 'Assembleias',
        description: 'Lista de assembleias para gerenciar',
        icon: IconAssembleias,
        route: '/assembleias',
        showSidebar: true,
        showDashboard: true,
    },
    {
        menu: LoginMenuEntity.homologacoes,
        name: 'Homologações',
        description: 'Lista de homologações para gerenciar',
        icon: IconAssembleias,
        route: '/homologacoes',
        showSidebar: true,
        showDashboard: true,
    },
    {
        menu: LoginMenuEntity.alertas,
        name: 'Alertas',
        description: 'Lista de alertas para gerenciar',
        icon: IconAlertas,
        route: '/alertas',
        showSidebar: true,
        showDashboard: true,
    },
    {
        menu: LoginMenuEntity.acessos,
        name: 'Acessos',
        description: '',
        icon: IconAcessos,
        route: '/acessos',
        showSidebar: true,
        showDashboard: false,
    },
    {
        menu: LoginMenuEntity.grupos,
        name: 'Grupos',
        description: 'Lista de Grupos de usuários para gerenciar permissões',
        icon: IconAcessos,
        route: '/grupos',
        showSidebar: false,
        showDashboard: true,
    },
    {
        menu: LoginMenuEntity.usuarios,
        name: 'Usuários',
        description: 'Lista de usuários para gerenciar permissões',
        icon: IconAcessos,
        route: '/usuarios',
        showSidebar: false,
        showDashboard: true,
    },
    {
        menu: LoginMenuEntity.configuracoes,
        name: 'Configurações',
        description: 'Lista de configurações para gerenciamento',
        icon: IconConfig,
        route: '/configuracoesEmpresas',
        showSidebar: true,
        showDashboard: true,
    },
];

export const screensPublics = [
    {
        name: 'Dashboard',
        description: '',
        icon: IconDashboard,
        route: '/dashboard',
    },
    {
        name: 'Credenciadas',
        description: 'Lista de assembleias credenciadas',
        icon: IconCheck,
        route: '/assembleiasCredenciadas',
    },
    {
        name: 'Assembleias',
        description: 'Lista de assembleias',
        icon: IconAssembleias,
        route: '/assembleiasPublicas/proximas',
    },
];