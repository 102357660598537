import ListCard from "../ListCard";

export type AssembleiaPerguntasData = {
    id: number;
    tipoPergunta: string;
    texto: string;
    clienteId?: string;
    clienteNome?: string;
    tag?: string;
    opcoes: {
        id: number | undefined;
        nome: string;
    }[];
}

type AssembleiaPerguntasPropsContent = {
    data: AssembleiaPerguntasData;
    skeleton?: never;
}

type AssembleiaPerguntasProps = AssembleiaPerguntasPropsContent;

export default function AssembleiaPerguntas(props: AssembleiaPerguntasProps){
    
    return (
        <>
            <ListCard>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton} className="fs-5">{props.data?.texto}</ListCard.Text>
                        <ListCard.Title>Opções: {props.data.opcoes.map((opcao)=>{return opcao.nome}).join(", ")}</ListCard.Title>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton} className="fs-5">Empresa</ListCard.Text>
                        <ListCard.Title>{!props.data.clienteId ? "Todos" : props.data.clienteNome}</ListCard.Title>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton} className="fs-5">Tag</ListCard.Text>
                        <ListCard.Title>{props.data.tag}</ListCard.Title>
                    </ListCard.Item>
                </ListCard.Content>
            </ListCard>
        </>
    )
}