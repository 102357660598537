const AsyncSelectStyle = {
    control: (styles: any, state: any) => {
        return {
            ...styles,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: state.isFocused ? '#86b7fe' : '#5D6365',
            boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : 'none',
            borderRadius: '30px',
            backgroundColor: state.isDisabled ? "#e9ecef" : "",
        }
    },
    multiValue: (styles: any, state: any) => {
        return {
            ...styles,
            borderRadius: '30px'
        }
    },
    singleValue: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
        }
    },
    valueContainer: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "12px",
            paddingBottom: "12px",
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '30px',
        overflow: 'hidden',
    }),
    option: (styles: any, state: any) => ({
        ...styles,
        paddingLeft: '24px',
        paddingRight: '24px'
    }),
}

const AsyncFloatingSelectStyle = {
    ...AsyncSelectStyle,
    valueContainer: (styles: any, state: any) => {
        return {
            ...styles,
            color: "#212529",
            height: 'calc(3.5rem + 2px)',
            lineHeight: 1.25,
            padding: '1rem 30px',
            paddingTop: '1.625rem',
            paddingBottom: '0.625rem',
        }
    },
}

const AsyncSelectTipoCredor = {
    control: (styles: any, state: any) => {
        return {
            ...styles,
            borderRadius: '30px',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: state.isFocused ? '#86b7fe' : '#5D6365',
        }
    },
    menu: (styles: any, state: any) => ({
        ...styles,
        borderRadius: '30px',
        overflow: 'hidden',
    }),
}

export { AsyncSelectStyle, AsyncFloatingSelectStyle, AsyncSelectTipoCredor };