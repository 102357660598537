import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import moment from 'moment';

import ListCard from "../ListCard";

type HomologacoesCardPropsContent = {
    data: any;
    skeleton?: never;
}

type HomologacoesCardPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type HomologacoesCardProps = HomologacoesCardPropsContent | HomologacoesCardPropsSkeleton;

export default function HomologacoesCard(props: HomologacoesCardProps){

    const navigate = useNavigate();
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/homologacao/"+props.data.assembleiaId);
        }
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }
    
    let dateFormat = moment(props.data?.data);

    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome da empresa</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.clientes.join(", ") ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Número do processo</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.numeroProcesso ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Administrador Judicial</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.administradorJudicial ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Data de criação</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{dateFormat.format("DD/MM/yyyy HH:mm")}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`}/>
                </ListCard.Action>
            </ListCard>
        </>
    )
}