import { useEffect, useRef, useState } from "react";
import A4BMeeting, { MeetingPollVoterType } from "@a4b/a4b-meeting";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { AssembleiaGetResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";
import { useToast } from "../../context/ToastContext";

enum tipoPergunta {
	Multiplas = "representative",
}

export default function Chamada() {
	const assembleiaService = new AssembleiaService();

	const iframeRef = useRef<HTMLIFrameElement>(null);
	const clickMeetingRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const { assembleiaId, atoId } = useParams();
	const { user, handleLogout } = useAuth();
	const { handleToast } = useToast();

	const [loading, setLoading] = useState(true);
	const [iframe, setIframe] = useState(true);

	const { isLoading, data, refetch } = useQuery(["assembleia", assembleiaId], () => fetchData(assembleiaId), { refetchOnWindowFocus: false });

	useEffect(() => {
		refetch();
	}, []);

	async function fetchData(id: any) {
		let resp: AssembleiaGetResponseEntity = await assembleiaService.get(id);
		if (resp.Result === 1 && resp.Data) {
			if (iframeRef.current) {
				iframeRef.current.style.height = `${window.innerHeight - 6}px`;
			}
			setLoading(false);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast("Chamada", resp.Message, 5000, "warning");
			handleVoltar();
		}

		return resp.Data;
	}

	function handleVoltar() {
		navigate(-1);
	}

	const ato = data?.atos.find((ato) => {
		return ato.id === Number(atoId);
	});
	const descricao = `${data?.clientes[0].clienteNome} ${data && data.clientes.length > 1 ? "e outros" : ""} - ${ato?.descricao}`;
	let grupos: string[] = [];
	if (user?.usuario.grupoId) {
		grupos =
			data?.clientes.map((cliente) => {
				return cliente.clienteNome;
			}) || [];
	} else {
		data?.clientes.forEach((cliente) => {
			if (
				cliente.classes.some((classe) => {
					return classe.credores.some((credor) => {
						return credor.representando;
					});
				})
			) {
				if (!grupos.includes(cliente.clienteNome)) {
					grupos.push(cliente.clienteNome);
				}
			}
		});
	}
	const perguntas = ato?.perguntas.map((pergunta) => {
		return {
			externalId: String(pergunta.id),
			text: pergunta.texto,
			voterType: tipoPergunta[pergunta.tipoPergunta as keyof typeof tipoPergunta],
			options: pergunta.opcoes.map((opcao) => {
				return {
					id: String(opcao.id),
					text: opcao.nome,
				};
			}),
			group: `${pergunta.clienteNome}`,
			tag: pergunta.tag,
		};
	});
	const tags = data?.quoruns.map((quorun) => {
		return quorun.valor;
	});

	return (
		<div className="d-flex justify-content-center position-relative" style={{ height: "100vh" }}>
			{(loading || isLoading) && (
				<div className="position-absolute d-flex justify-content-center align-items-center flex-column bg-white" style={{ inset: 0, zIndex: 100 }}>
					<Spinner className="mb-2" />
					Carregando
				</div>
			)}
			<div className="d-flex flex-fill">
				{iframe && (
					<div className="flex-fill" style={{ background: "#272F31" }}>
						<div className="flex-fill" ref={clickMeetingRef}>
							<iframe
								className="w-100"
								src={user?.usuario.grupoId ? data?.clickmeetingUrlHost : data?.clickmeetingUrlParticipant}
								ref={iframeRef}
								allow="camera; microphone; fullscreen; speaker; display-capture;"
							/>
						</div>
					</div>
				)}
				<div style={{ width: iframe ? 400 : "100%" }}>
					{!isLoading && !loading && (
						<A4BMeeting
							MeetingID={String(ato?.chamadaId)}
							MeetingToken={String(ato?.chamadaToken)}
							MeetingOwner={user?.usuario.grupoId ? true : false}
							MeetingDate={ato?.dataPrevista}
							MeetingName={descricao}
							ParticipantID={String(user?.usuario.id)}
							ParticipantName={String(user?.usuario.nome)}
							ScreenShare={user?.usuario.grupoId ? true : false}
							ChatEnabled={true}
							PollsEnabled={true}
							ParticipantsEnabled={false}
							LockEnable={true}
							Polls={perguntas}
							PollsConfig={{
								voterTypes: false,
								voterTypeDefault: MeetingPollVoterType.representative,
								groups: grupos,
								groupsPlaceholder: "Selecione a empresa",
								tags: tags,
								tagsPlaceholder: "Selecione o quórum",
							}}
							EndBack={true}
							EndBackCallback={() => {
								navigate(`/assembleia/${assembleiaId}`);
							}}
							EndCallback={() => {
								setIframe(false);
							}}
							Theme={{
								logo: {
									onWhite: `${process.env.PUBLIC_URL}/von_meeting_logo_on_white.svg`,
									onBlack: `${process.env.PUBLIC_URL}/von_meeting_logo_on_black.svg`,
								},
								primary: "#C2F94B",
								primaryText: "#272F31",
								secondary: "#272F31",
								secondaryText: "#FFFFFF",
								danger: "#EB5757",
								dangerText: "#272F31",
								warning: "#F2C94C",
								warningText: "#272F31",
								disabled: "#BDBDBD",
								disabledText: "#272F31",
								background: "#272F31",
								backgroundSidebar: "#fff",
								backgroundControl: "#3B4143",
							}}
							Video={{
								frameRate: 24,
								width: 320,
								height: 240,
							}}
							apiUrl={process.env.REACT_APP_API_BASE_URL ?? ""}
							wsUrl={process.env.REACT_APP_WS_URL ?? ""}
							turnUrl={process.env.REACT_APP_TURN_URL ?? ""}
							WebRTC={false}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
