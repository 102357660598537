import axios from "axios";
import { ApiResponseEntity } from "../entities/ApiResponseEntity";
import { AlertaAutocompleteResponseEntity, AlertaEntity, AlertaGetListResponseEntity, AlertaGetNavegacaoResponseEntity, AlertaGetResponseEntity, AlertaPostResponseEntity } from "../entities/AlertaEntity";
import { UsuarioEntity, UsuarioGetAplicativosResponseEntity, UsuarioGetEmpresasResponseEntity, UsuarioGetGruposResponseEntity, UsuarioGetListResponseEntity, UsuarioGetResponseEntity, UsuarioPostResponseEntity } from "../entities/UsuarioEntity";
import { GrupoGetListResponseEntity, GrupoGetPermissoesResponseEntity, GrupoGetResponseEntity, GrupoPostEntity, GrupoPostResponseEntity } from "../entities/GrupoEntity";
import { LoginEntity, LoginPostResponseEntity, LoginRecuperarSenhaResponseEntity } from "../entities/LoginEntity";
import { ConfiguracaoEntity, ConfiguracaoGetEmpresasResponseEntity, ConfiguracaoGetResponseEntity, ConfiguracaoPostResponseEntity } from "../entities/ConfiguracaoEntity";

const Request = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL})

/* LOGIN */
export async function login(email: string, password: string): Promise<LoginPostResponseEntity> {
    let response;
    try{
        let req = await Request.post<LoginPostResponseEntity>("/api/v1/Login",{
            login: email,
            senha: password,
            appVersion: "0.0.1",
            deviceToken: "",
            deviceType: 0,
            osVersion: ""
        });
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function loginRecuperarSenha(email: string, cpf: string, cnpj: string): Promise<LoginRecuperarSenhaResponseEntity> {
    let response;
    try{
        let req = await Request.post<LoginRecuperarSenhaResponseEntity>("/api/v1/Cadastro/RecuperarSenha",{email: email, cpfCnpj: cpf ? cpf : cnpj});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* ALERTA */
export async function alertaGetList(pagina: number, filtro?: {filtro?: string, periodoInicial?: string, periodoFinal?: string}): Promise<AlertaGetListResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let parametros = filtro ? Object.keys(filtro).map((key)=>{ return filtro[key as keyof typeof filtro] ? `&${key}=${filtro[key as keyof typeof filtro]}` : '' }).join('') : '';
    let response;
    try{
        let req = await Request.get<AlertaGetListResponseEntity>(`/api/v1/alertas/GetListGerenciador?pagina=${pagina}${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaGetNavegacao(aplicativo: number): Promise<AlertaGetNavegacaoResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<AlertaGetNavegacaoResponseEntity>(`/api/v1/alertas/GetServicos?aplicativoId=${aplicativo}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaGet(id: string): Promise<AlertaGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<AlertaGetResponseEntity>(`/api/v1/alertas/getAlerta?alertaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaPost(data: AlertaEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<AlertaPostResponseEntity>("/api/v1/alertas/PostAlerta", data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/api/v1/alertas/Delete?alertaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function alertaAutocomplete(search: string): Promise<AlertaAutocompleteResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<AlertaAutocompleteResponseEntity>(`api/v1/alertas/Autocomplete?param=${search}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* GRUPO */
export async function grupoGetList(pagina: number, filtro?: {filtro?: string}): Promise<GrupoGetListResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let parametros = filtro ? Object.keys(filtro).map((key)=>{ return filtro[key as keyof typeof filtro] ? `&${key}=${filtro[key as keyof typeof filtro]}` : '' }).join('') : '';
    let response;
    try{
        let req = await Request.get<GrupoGetListResponseEntity>(`/api/v1/Grupo/GetList?pagina=${pagina}${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function grupoGetPermissoes(): Promise<GrupoGetPermissoesResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<GrupoGetPermissoesResponseEntity>(`/api/v1/Grupo/GetPermissoes`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function grupoGet(id: string): Promise<GrupoGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<GrupoGetResponseEntity>(`/api/v1/Grupo/GetGrupo?grupoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function grupoPost(data: GrupoPostEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<GrupoPostResponseEntity>(`/api/v1/Grupo/PostGrupo${data.empresaId ? `?empresaId=${data.empresaId}` : ""}`,data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function grupoDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/api/v1/Grupo/Delete?grupoId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* USUARIO */
export async function usuarioGetList(pagina: number, filtro?: {filtro?: string}): Promise<UsuarioGetListResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let parametros = filtro ? Object.keys(filtro).map((key)=>{ return filtro[key as keyof typeof filtro] ? `&${key}=${filtro[key as keyof typeof filtro]}` : '' }).join('') : '';
    let response;
    try{
        let req = await Request.get<UsuarioGetListResponseEntity>(`/api/v1/Usuarios/GetList?pagina=${pagina}${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGetEmpresas(): Promise<UsuarioGetEmpresasResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetEmpresasResponseEntity>(`/api/v1/Usuarios/GetEmpresas`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGetGrupos(empresa?: number): Promise<UsuarioGetGruposResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetGruposResponseEntity>(`/api/v1/Usuarios/GetGrupos${empresa ? `?empresaId=${empresa}` : ""}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGetAplicativos(empresa?: number): Promise<UsuarioGetAplicativosResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetAplicativosResponseEntity>(`/api/v1/Usuarios/GetAplicativos${empresa ? `?empresaId=${empresa}` : ""}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioGet(id: string): Promise<UsuarioGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<UsuarioGetResponseEntity>(`/api/v1/Usuarios/GetUsuario?usuarioId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioPost(data: UsuarioEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<UsuarioPostResponseEntity>("/api/v1/Usuarios/PostUsuario", data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function usuarioDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/api/v1/Usuarios/Delete?usuarioId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

/* CONFIGURACOES */
export async function configuracaoGetEmpresas(): Promise<ConfiguracaoGetEmpresasResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<ConfiguracaoGetEmpresasResponseEntity>(`/api/v1/Configuracao/GetEmpresas`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function configuracaoGetEmpresa(empresaId: string): Promise<ConfiguracaoGetResponseEntity> {
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.get<ConfiguracaoGetResponseEntity>(`/api/v1/Configuracao/GetEmpresa?empresaId=${empresaId}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function configuracaoPostEmpresa(data: ConfiguracaoEntity){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.post<ConfiguracaoPostResponseEntity>("/api/v1/Configuracao/PostEmpresa",data,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}

export async function configuracaoDelete(id: number){
    let user = JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    let response;
    try{
        let req = await Request.delete<ApiResponseEntity>(`/api/v1/Configuracao/Delete?empresaId=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
        response = req.data;
    }catch(e){
        return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
    }
    return response;
}





/* API */
export default class ApiService{

    protected request = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

    protected mock = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL_MOCK});

    protected user(){
        return JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
    }

    public async login(email: string, password: string): Promise<LoginPostResponseEntity> {
        let response;
        try{
            let req = await Request.post<LoginPostResponseEntity>("/api/v1/Login",{
                login: email,
                senha: password,
                appVersion: "0.0.1",
                deviceToken: "",
                deviceType: 0,
                osVersion: ""
            });
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }
}