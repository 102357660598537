import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiCogOutline } from "@mdi/js";
import { useInfiniteQuery } from "react-query";

import Layout from "../../components/Layout";
import { configuracaoGetEmpresas } from "../../services/ApiService";
import InfiniteScroll from "../../components/InfiniteScroll";
import { useAuth } from "../../context/AuthContext";
import EmpresasCard from "../../components/EmpresasCard";
import { ConfiguracaoGetEmpresasResponseEntity } from "../../entities/ConfiguracaoEntity";

export default function ConfiguracoesEmpresas(){

    const navigate = useNavigate();
    const { handleLogout } = useAuth();

    const [message, setMessage] = useState("");

    const { data, hasNextPage, isLoading, isFetching, isFetchingNextPage, isRefetching, fetchNextPage } = useInfiniteQuery<ConfiguracaoGetEmpresasResponseEntity>('configuracoesEmpresas', () => fetchData(),{});

    async function fetchData(){
        let resp = await configuracaoGetEmpresas();
        if(resp.Result===99){
            handleLogout();
            navigate("/");
        }
        setMessage(resp.Message);
        return resp;
    }

    async function fetchMoreData(){
        fetchNextPage();
    }

    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <Icon path={mdiCogOutline} size={1} className="me-1"/> Configurações
                { isRefetching && (
                    <Spinner size="sm" className="ms-1" variant="secondary" />
                )}
                <div className="float-right ms-auto" style={{marginTop: -10, marginBottom: -10}}>
                    <Button variant="primary" onClick={()=>{ navigate("/configuracaoFormulario") }}>Cadastrar</Button>{' '}
                </div>
            </h5>

            <Row className="mb-4">
                {isLoading ? (
                    <Col md={12}>
                        <EmpresasCard skeleton/>
                    </Col>
                ) : (
                    message ? (
                        <Col md={12}>
                            <Alert variant="secondary" className="text-center">{message}</Alert>
                        </Col>
                    ) : (
                        <>
                            {!data?.pages[0].Data?.length ? (
                                <Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
                                    <Icon path={mdiCardRemoveOutline} size={1} className="me-2"/>
                                    Nenhum registro encontrado
                                </Alert>
                            ) : data?.pages.map((dataPage, dataPageIndex)=>{
                                return (
                                    <React.Fragment key={dataPageIndex}>
                                        {dataPage.Data?.map((item, index)=>{
                                            return (
                                                <Col md={12} key={index}>
                                                    <EmpresasCard
                                                        data={item}
                                                    />
                                                </Col>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )
                )}
                <Col md={12} className="text-center">
                    <InfiniteScroll
                        more={hasNextPage}
                        load={fetchMoreData}
                        loader={<EmpresasCard skeleton/>}
                        loading={isFetching || isFetchingNextPage}
                    />
                </Col>
            </Row>
        </Layout>
    )
}