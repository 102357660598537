import ListCard from "../ListCard";

export type AssembleiaCredoresCardData = {
    credorNome: string;
    credorTotal: string;
}

type AssembleiaCredoresCardPropsContent = {
    data: AssembleiaCredoresCardData;
    classe: string;
    skeleton?: never;
}

type AssembleiaCredoresCardPropsSkeleton = {
    data?: never;
    classe?: never;
    skeleton: true;
}

type AssembleiaCredoresCardProps = AssembleiaCredoresCardPropsContent | AssembleiaCredoresCardPropsSkeleton;

export default function AssembleiaCredoresCard(props: AssembleiaCredoresCardProps){
    
    return (
        <>
            <ListCard>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.credorNome}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton}>{props.classe}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.credorTotal}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
            </ListCard>
        </>
    )
}