import { Image, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { screens, screensPublics } from "../../config/screens";

import IconSVG from "../IconSVG";
import { ReactComponent as IconCredor } from "../../icons/credor.svg";
import { ReactComponent as IconMais } from "../../icons/mais.svg";

export default function Sidebar() {

    const { user, routeActive } = useAuth();
    const navigate = useNavigate();

    const handleLink = async (url: string) => {
        navigate(url);
    }

    return (
        <Navbar variant="dark" bg="dark" expand="md" id="sidebar" className="d-block d-md-flex flex-column d-sm-block flex-shrink-0 p-4 col-md-4 col-lg-4 col-xl-3 sticky-top shadow">
            <Navbar.Brand href="#" onClick={() => handleLink("/dashboard")} className="w-100 me-0">
                <Image fluid className="ps-2" alt="Von Meeting" src={process.env.PUBLIC_URL + '/logo.png'} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="float-end" />
            <Navbar.Collapse id="basic-navbar-nav" className="w-100" >
                <Nav className="flex-column mb-auto w-100 h-100">
                    <Nav.Item>
                        <hr />
                    </Nav.Item>
                    {!user ? (
                        <Nav.Item>
                            <Nav.Link className="p-2 text-uppercase align-items-center d-flex" onClick={() => handleLink("/")}>
                                <IconSVG path={IconCredor} width={24} height={24} className="me-3 text-white" />
                                Entrar
                            </Nav.Link>
                            <Nav.Link className="p-2 text-uppercase align-items-center d-flex" onClick={() => handleLink("/?stage=2")}>
                                <IconSVG path={IconMais} width={24} height={24} className="me-3 text-white" />
                                Cadastrar
                            </Nav.Link>
                        </Nav.Item>
                    ):(
                        <>
                            {!user?.usuario.grupoId ? (
                                <>
                                    {screensPublics.map((screen, index) => {
                                        return (
                                            <Nav.Item key={index}>
                                                <Nav.Link className="p-2 text-uppercase align-items-center d-flex" active={routeActive === screen.route} onClick={() => handleLink(screen.route)}>
                                                    <IconSVG path={screen.icon} width={24} height={24} className="me-3 text-white" />
                                                    {screen.name}
                                                </Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </>
                            ):(
                                <>
                                    {screens.filter((screen) => screen.showSidebar && (screen.menu === null || (screen.menu !== null && user?.menus?.includes(screen.menu)))).map((screen, index) => {
                                        return (
                                            <Nav.Item key={index}>
                                                <Nav.Link className="p-2 text-uppercase align-items-center d-flex" active={routeActive === screen.route} onClick={() => handleLink(screen.route)}>
                                                    <IconSVG path={screen.icon} width={24} height={24} className="me-3 text-white" />
                                                    {screen.name}
                                                </Nav.Link>
                                            </Nav.Item>
                                        );
                                    })}
                                </>
                            )}
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}