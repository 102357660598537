import ApiService from "./ApiService";
import { ApiResponseEntity } from "../entities/ApiResponseEntity";
import {
    PessoaPostRequestEntity,
    PessoaPostResponseEntity,
    PessoaSendLinkResponseEntity,
    PessoaValidateTokenResponseEntity,
    PessoaGetResponseEntity,
    PessoaAtualizarRequestEntity,
    PessoaAtualizarResponseEntity
} from "../entities/PessoaEntity";

export default class PessoaService extends ApiService {

    public async post(data: PessoaPostRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<PessoaPostResponseEntity>("/api/v1/Cadastro", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async get(data: {id: number}): Promise<PessoaGetResponseEntity> {        
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<PessoaGetResponseEntity>(`/api/v1/Cadastro/Get?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async atualizar(data: PessoaAtualizarRequestEntity){
        let user = this.user();
        let response;
        try{
            let req = await this.request.post<PessoaAtualizarResponseEntity>("/api/v1/Cadastro/Atualizar", data, {headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async sendLink(data: {pessoaId: number}): Promise<PessoaSendLinkResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<PessoaSendLinkResponseEntity>(`/api/v1/Cadastro/SendLink?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async validateToken(data: {token: string}): Promise<PessoaValidateTokenResponseEntity> {
        let user = this.user();
        let parametros = data ? Object.keys(data).map((key)=>{ return data[key as keyof typeof data] ? `&${key}=${data[key as keyof typeof data]}` : '' }).join('') : '';
        let response;
        try{
            let req = await this.request.get<PessoaValidateTokenResponseEntity>(`/api/v1/Cadastro/ValidateToken?${parametros}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

    public async delete(id: number){
        let user = this.user();
        let response;
        try{
            let req = await this.request.delete<ApiResponseEntity>(`/api/v1/Cadastro/Delete?id=${id}`,{headers:{Authorization: `Bearer ${user.hashLogin}`}});
            response = req.data;
        }catch(e){
            return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" }
        }
        return response;
    }

}