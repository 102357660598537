import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";
import { useAuth } from "../../context/AuthContext";
import { screens } from "../../config/screens";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconDashboard } from "../../icons/dashboard.svg";
import DashboardAssembleiasCredenciadas from "../../components/DashboardAssembleiasCredenciadas";
import DashboardAssembleiasProximasFinalizadas from "../../components/DashboardAssembleiasProximasFinalizadas";

export default function Dashboard(){

    const { user } = useAuth();
    const navigate = useNavigate();

    function handleClick(item: string){
        navigate(item);
    }
    
    return (
        <Layout>

            <h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
                <IconSVG path={IconDashboard} width={24} height={24} className="me-1"/>
                Dashboard
            </h5>

            <Row className="mb-4">
                {!user?.usuario.grupoId ? (
                    <>
                        <Col xl={6} className="mb-4">
                            <DashboardAssembleiasCredenciadas/>
                        </Col>
                        <Col xl={6}>
                            <DashboardAssembleiasProximasFinalizadas/>
                        </Col>
                    </>
                ):(
                    <>
                        {screens.filter((screen)=> screen.showDashboard && (screen.menu===null || (screen.menu!==null && user?.menus?.includes(screen.menu))) ).map((screen, index)=>{
                            return (
                                <Col lg={6} key={index}>
                                    <ListCard onClick={()=>{ handleClick(screen.route); }}>
                                        <ListCard.Icon>
                                            <IconSVG path={screen.icon} width={24} height={24} className="text-muted me-3"/>
                                        </ListCard.Icon>
                                        <ListCard.Content>
                                            <div className="flex-fill me-2">
                                                <div className="text-muted fw-lighter">{screen.name}</div>
                                                <div className="fw-light">{screen.description}</div>
                                            </div>
                                        </ListCard.Content>
                                        <ListCard.Action>
                                            <Icon path={mdiChevronRight} size={1} className="text-muted m-2"/>
                                        </ListCard.Action>
                                    </ListCard>
                                </Col>
                            );
                        })}
                    </>
                )}
            </Row>
        </Layout>
    )
}