import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { GrupoGetListEntity } from "../../entities/GrupoEntity";
import { grupoDelete } from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import ListCard from "../ListCard";
import IconSVG from "../IconSVG";

import { ReactComponent as IconFechar } from "../../icons/fechar.svg";

type GruposCardPropsContent = {
    data: GrupoGetListEntity;
    skeleton?: never;
}

type GruposCardPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type GruposCardProps = GruposCardPropsContent | GruposCardPropsSkeleton;

export default function GruposCard(props: GruposCardProps){

    const navigate = useNavigate();
    const { handleLogout } = useAuth();
    const { handleToast } = useToast();
    
    const [removeModal, setRemoveModal] = useState(false);
    const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
    const [removed, setRemoved] = useState(false);
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/grupoFormulario/"+props.data.grupoId);
        }
    }

    function handleClickRemove(e: any){
        e.stopPropagation();
        setRemoveModal(true);
    }

    async function handleRemove(){
        if(!props.skeleton && props.data){
            setRemoveLoadingModal(true);
            let resp = await grupoDelete(props.data.grupoId);
            if(resp.Result===1){
                queryClient.refetchQueries('grupos');
                queryClient.invalidateQueries(['grupo', props.data.grupoId]);
                setRemoved(true);
            }else{
                if(resp.Result===99){
                    handleLogout();
                    navigate("/");
                }
                handleToast("Grupo", resp.Message, 5000, "danger");
            }
            setRemoveModal(false);
            setRemoveLoadingModal(false);
        }
    }

    function handleCloseModal(){
        if(!removeLoadingModal){
            setRemoveModal(false);
        }
    }

    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    if(removed){
        return <></>
    }
    
    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}><IconSVG path={IconFechar} width={32} height={32}/></Button>
                    <Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`}/>
                </ListCard.Action>
            </ListCard>

            <Modal show={removeModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remover Grupo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    { !removeLoadingModal ? (
                        "Deseja realmente remover este grupo?"
                    ) : (
                        <Spinner />
                    )}
                </Modal.Body>
                { !removeLoadingModal && (
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Cancelar</Button>
                        <Button variant="danger" onClick={handleRemove}>Remover</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}