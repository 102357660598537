import { useState } from "react";
import { Badge, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import moment, { Moment } from "moment";

import ListCard from "../../components/ListCard";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import HomologacaoService from "../../services/HomologacaoService";
import {
	HomologacaoGetAssembleiaResponseEntity,
	HomologacaoGetCredenciamentosResponseEntity,
	HomologacaoGetDocumentoResponseEntity,
	HomologacaoPostAprovarRequestEntity,
	HomologacaoPostAprovarResponseEntity,
	HomologacaoPostReprovarRequestEntity,
	HomologacaoPostReprovarResponseEntity,
} from "../../entities/HomologacaoEntity";
import Layout from "../../components/Layout";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconDownload } from "../../icons/download.svg";
import { ReactComponent as IconFechar } from "../../icons/fechar.svg";
import { ReactComponent as IconCheck } from "../../icons/check.svg";
import { ReactComponent as IconAssembleias } from "../../icons/assembleias.svg";
import { Color } from "react-bootstrap/esm/types";

const screenConfig = {
	toastTitle: "Homologação",
	queryId: "homologacao",
	routeBack: "/homologacoes",
};

type CredorType = {
	credorId: number;
	credorNome: string;
	documentos: {
		credorDocumentoId: number;
		credorRepresentadoId: number;
		pessoaTipoId: number;
		tipoArquivoId: number;
		tipoArquivoNome: string;
		credorArquivoStatusId: number;
		credorArquivoStatusNome: string;
		credorArquivoStatusColor: string;
		mensagem: string;
		dataEnvio: string;
		nome: string;
	}[];
};

export default function Homologacao() {
	const homologacaoService = new HomologacaoService();

	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();

	const [stage, setStage] = useState(0);
	const [numeroProcesso, setNumeroProcesso] = useState("");
	const [administradorJudicial, setAdministradorJudicial] = useState("");
	const [data, setData] = useState<Moment>();
	const [clientes, setClientes] = useState<NonNullable<HomologacaoGetAssembleiaResponseEntity["Data"]>["clientes"]>();
	const [pessoaId, setPessoaId] = useState("");
	const [pessoaNome, setPessoaNome] = useState("");
	const [clienteNome, setClienteNome] = useState("");
	const [credores, setCredores] = useState<CredorType[]>();

	const [baixarLoading, setBaixarLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [modalAprovarDocumento, setModalAprovarDocumento] = useState(false);
	const [modalReprovarDocumento, setModalReprovarDocumento] = useState(false);
	const [formDocumentoId, setFormDocumentoId] = useState<number>();
	const [formReprovarDocumentoMotivo, setFormReprovarDocumentoMotivo] = useState("");

	const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>([screenConfig.queryId, id], () => fetchDataAssembleia(id), { enabled: id !== undefined });
	const queryCredores = useQuery<boolean>([screenConfig.queryId, "credores", pessoaId], () => fetchDataCredores(pessoaId), { enabled: pessoaId !== "" });

	async function fetchDataAssembleia(id: any) {
		let resp: HomologacaoGetAssembleiaResponseEntity = await homologacaoService.getAssembleia(id);
		if (resp.Result === 1 && resp.Data) {
			setNumeroProcesso(resp.Data.numeroProcesso);
			setAdministradorJudicial(resp.Data.administradorJudicial);
			setData(moment(resp.Data.data));
			setClientes(resp.Data.clientes);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	async function fetchDataCredores(pessoaId: string) {
		let resp: HomologacaoGetCredenciamentosResponseEntity = await homologacaoService.getCredenciamentos({ assembleiaId: Number(id), pessoaTipoId: Number(pessoaId) });
		if (resp.Result === 1 && resp.Data) {
			setCredores(resp.Data.credores);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			setPessoaId("");
			setStage(0);
		}

		return true;
	}

	function handlePessoa(id: string, nome: string, cliente: string) {
		setCredores(undefined);
		if (id === pessoaId) {
			queryCredores.refetch();
		}
		setPessoaId(id);
		setPessoaNome(nome);
		setClienteNome(cliente);
		setStage(1);
	}

	function handleVoltar(): void {
		navigate(screenConfig.routeBack);
	}

	async function handleBaixarDocumento(id: number) {
		setBaixarLoading(true);
		let resp: HomologacaoGetDocumentoResponseEntity = await homologacaoService.getDocumento({ documentoId: id });
		if (resp.Result === 1 && resp.Data) {
			let link = document.createElement("a");
			link.download = resp.Data.nome;
			link.href = `data:${resp.Data.contentType};base64,${resp.Data.bytes}`;
			link.click();
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		}
		setBaixarLoading(false);
	}

	function handleAprovarDocumento(id: number) {
		setFormDocumentoId(id);
		setModalAprovarDocumento(true);
	}

	function handleModalAprovarDocumentoClose() {
		setModalAprovarDocumento(false);
	}

	async function handleModalAprovarDocumentoAprovar() {
		setModalLoading(true);

		const data: HomologacaoPostAprovarRequestEntity = {
			documentoId: formDocumentoId ?? 0,
		};

		let resp: HomologacaoPostAprovarResponseEntity = await homologacaoService.postAprovar(data);
		if (resp.Result !== 1) {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		} else {
			queryCredores.refetch();
			handleToast(screenConfig.toastTitle, resp.Message, 5000);
		}

		setModalAprovarDocumento(false);
		setModalLoading(false);
	}

	function handleReprovarDocumento(id: number) {
		setFormDocumentoId(id);
		setModalReprovarDocumento(true);
	}

	function handleModalReprovarDocumentoClose() {
		setModalReprovarDocumento(false);
	}

	async function handleModalReprovarDocumentoReprovar() {
		setModalLoading(true);

		const data: HomologacaoPostReprovarRequestEntity = {
			documentoId: formDocumentoId ?? 0,
			mensagem: formReprovarDocumentoMotivo,
		};

		let resp: HomologacaoPostReprovarResponseEntity = await homologacaoService.postReprovar(data);
		if (resp.Result !== 1) {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, "A mensagem é obrigatória para reprovar", 5000, "danger");
		} else {
			handleToast(screenConfig.toastTitle, resp.Message, 5000);
			queryCredores.refetch();
		}

		setModalReprovarDocumento(false);
		setFormReprovarDocumentoMotivo("");
		setModalLoading(false);
	}

	let formStatus;
	if (formReprovarDocumentoMotivo === "") {
		formStatus = true;
	} else {
		formStatus = false;
	}
	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center">
				<IconSVG path={IconAssembleias} width={24} height={24} className="me-1" /> Homologação
				{(isLoading || isFetching || isRefetching || isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} /> Voltar
					</Button>
				</div>
			</h5>

			{stage === 0 && (
				<>
					<div className="mb-4">
						<ListCard.Item>
							<ListCard.Title>Número do processo</ListCard.Title>
							<ListCard.Text>{numeroProcesso}</ListCard.Text>
						</ListCard.Item>
					</div>

					<div className="mb-4">
						<ListCard.Item>
							<ListCard.Title>Administrador Judicial</ListCard.Title>
							<ListCard.Text>{administradorJudicial}</ListCard.Text>
						</ListCard.Item>
					</div>

					<div className="mb-4">
						<ListCard.Item>
							<ListCard.Title>Data criação</ListCard.Title>
							<ListCard.Text>{data?.format("DD/MM/yyyy HH:mm")}</ListCard.Text>
						</ListCard.Item>
					</div>

					<div className="mb-2">
						<ListCard.Item>
							<ListCard.Title>Documentos</ListCard.Title>
							{clientes?.length === 0 && <ListCard.Text>Nenhum documento encontrado</ListCard.Text>}
						</ListCard.Item>
					</div>

					{clientes &&
						clientes.map((cliente, clienteIndex: number) => {
							return (
								<div className="mb-4" key={clienteIndex}>
									<h5 className="mb-0 d-flex align-items-center">
										<span className="text-underline">{cliente.clienteNome}</span>
									</h5>
									{cliente.procuradores.map((procurador) => {
										return procurador.pessoas.map((pessoa, pessoaIndex: number) => {
											let badgeText: Color = "dark";
											return (
												<ListCard
													key={pessoaIndex}
													onClick={() => {
														handlePessoa(`${procurador.procuradorId}`, procurador.procuradorNome, cliente.clienteNome);
													}}
												>
													<ListCard.Content>
														<ListCard.Item>
															<ListCard.Title>Procurador</ListCard.Title>
															<ListCard.Text>{procurador.procuradorNome}</ListCard.Text>
														</ListCard.Item>
														<ListCard.Item>
															<ListCard.Title>Credor</ListCard.Title>
															<ListCard.Text>{pessoa.pessoaNome}</ListCard.Text>
														</ListCard.Item>
														<ListCard.Item>
															<ListCard.Title>Status</ListCard.Title>
															<ListCard.Text>
																<Badge bg="" text={badgeText} style={{ backgroundColor: pessoa.statusColor }}>
																	{pessoa.status}
																</Badge>
															</ListCard.Text>
														</ListCard.Item>
													</ListCard.Content>
													<ListCard.Action>
														<Icon path={mdiChevronRight} size={1} className={`text-muted m-2`} />
													</ListCard.Action>
												</ListCard>
											);
										});
									})}
								</div>
							);
						})}
				</>
			)}

			{stage === 1 && (
				<>
					<h5 className="mb-4">
						Relação de credores - <span className="text-underline">{clienteNome}</span>
						<div className="mt-2 fs-6 fw-light">Aceite ou reprove cada documento de cada credor</div>
					</h5>

					<h5 className="mb-4 d-flex align-items-center">{pessoaNome}</h5>

					{credores &&
						credores.map((credor, credorIndex: number) => {
							return (
								<div className="mb-4" key={credorIndex}>
									<div className="mb-0 fs-6" style={{ fontWeight: 500 }}>
										{credor.credorNome}
									</div>
									{credor.documentos.map((documento, documentoIndex: number) => {
										let badgeText: Color = "dark";

										return (
											<ListCard key={documentoIndex} onClick={() => {}}>
												<ListCard.Content>
													<ListCard.Item>
														<ListCard.Title>Tipo de documento</ListCard.Title>
														<ListCard.Text>{documento.tipoArquivoNome}</ListCard.Text>
													</ListCard.Item>
													<ListCard.Item>
														<ListCard.Title>Nome</ListCard.Title>
														<ListCard.Text>{documento.nome}</ListCard.Text>
													</ListCard.Item>
													<ListCard.Item>
														<ListCard.Title>Data de envio</ListCard.Title>
														<ListCard.Text>{moment(documento.dataEnvio).format("DD/MM/yyyy HH:mm")}</ListCard.Text>
													</ListCard.Item>
													<ListCard.Item>
														<ListCard.Title>Status</ListCard.Title>
														<ListCard.Text style={{ display: "block", textOverflow: "unset", wordBreak: "unset" }}>
															<Badge bg="" text={badgeText} style={{ backgroundColor: documento.credorArquivoStatusColor }}>
																{documento.credorArquivoStatusNome}
															</Badge>
															<br />
															{documento.mensagem}
														</ListCard.Text>
													</ListCard.Item>
												</ListCard.Content>
												<ListCard.Action>
													<Button
														variant="light"
														className={`p-1 me-2`}
														title="Baixar"
														onClick={() => {
															handleBaixarDocumento(documento.credorDocumentoId);
														}}
														disabled={baixarLoading}
													>
														<IconSVG path={IconDownload} width={24} height={24} className="me-2" /> Baixar
													</Button>
													<Button
														variant="light"
														className={`p-1 me-2`}
														title="Aprovar"
														onClick={() => {
															handleAprovarDocumento(documento.credorDocumentoId);
														}}
														disabled={![1, 2, 4].includes(documento.credorArquivoStatusId)}
													>
														<IconSVG path={IconCheck} width={24} height={24} className="me-2" /> Aprovar
													</Button>
													<Button
														variant="light"
														className={`p-1 me-2`}
														title="Reprovar"
														onClick={() => {
															handleReprovarDocumento(documento.credorDocumentoId);
														}}
														disabled={![1, 2, 3].includes(documento.credorArquivoStatusId)}
													>
														<IconSVG path={IconFechar} width={24} height={24} className="me-2" /> Reprovar
													</Button>
												</ListCard.Action>
											</ListCard>
										);
									})}
								</div>
							);
						})}

					<Button
						className="me-2 p-3"
						variant="secondary"
						type="button"
						onClick={() => {
							setStage(0);
							refetch();
						}}
						style={{ width: 180 }}
					>
						Voltar
					</Button>
				</>
			)}

			<Modal show={modalAprovarDocumento} onHide={handleModalAprovarDocumentoClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Aprovar documento</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!modalLoading ? "Deseja realmente aprovar este documento?" : <Spinner />}</Modal.Body>
				{!modalLoading && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleModalAprovarDocumentoClose}>
							Cancelar
						</Button>
						<Button variant="primary" onClick={handleModalAprovarDocumentoAprovar}>
							Aprovar
						</Button>
					</Modal.Footer>
				)}
			</Modal>

			<Modal show={modalReprovarDocumento} onHide={handleModalReprovarDocumentoClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Reprovar documento</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!modalLoading ? (
						<Form.Group className="mb-3" controlId="reprovarDocumento">
							<Form.Label>Deseja realmente reprovar este documento?</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								placeholder="Informe o motivo da reprovação do documento"
								value={formReprovarDocumentoMotivo}
								onChange={(event) => {
									setFormReprovarDocumentoMotivo(event.currentTarget.value);
								}}
							/>
						</Form.Group>
					) : (
						<div className="text-center">
							<Spinner />
						</div>
					)}
				</Modal.Body>
				{!modalLoading && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleModalReprovarDocumentoClose}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleModalReprovarDocumentoReprovar} disabled={formStatus}>
							Reprovar
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</Layout>
	);
}
