import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import moment from 'moment';

import ListCard from "../ListCard";
import IconSVG from "../IconSVG";
import { ReactComponent as IconExibir } from "../../icons/exibir.svg";

type AssembleiasPublicasCardPropsContent = {
    data: any;
    skeleton?: never;
}

type AssembleiasPublicasCardPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type AssembleiasPublicasCardProps = AssembleiasPublicasCardPropsContent | AssembleiasPublicasCardPropsSkeleton;

export default function AssembleiasPublicasCard(props: AssembleiasPublicasCardProps){

    const navigate = useNavigate();
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/assembleia/"+props.data.assembleiaId);
        }
    }
    
    let skeletonButton = "";
    if(props.skeleton){
        skeletonButton = "skeleton skeleton-button";
    }

    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome da empresa</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.clientes.join(", ") ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Número do processo</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.numeroProcesso ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Próximo Ato</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.dataProximoAto ? moment(props.data?.dataProximoAto).format("DD/MM/yyyy HH:mm") : ""}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Button variant="light" className={`p-1 me-2 ${skeletonButton}`} title="Exibir">
                        <IconSVG path={IconExibir} width={32} height={32}/>
                    </Button>
                    <Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`}/>
                </ListCard.Action>
            </ListCard>
        </>
    )
}