import { Container } from "react-bootstrap";
import Header from "../Header";
import Body from "../Body";
import Main from "../Main";
import Sidebar from "../Sidebar";
import { ReactNode } from "react";

type LayoutProps = {
    children: ReactNode;
}

export default function Layout({children}: LayoutProps){
	return(
		<Container fluid className="layout" style={{minHeight: "100dvh"}}>
			<Body>
				<Sidebar/>
				<Main>
					<Header/>
					{ children }
				</Main>
			</Body>
		</Container>
	)
}