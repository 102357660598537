import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import moment from 'moment';
import { Color } from "react-bootstrap/esm/types";

import ListCard from "../ListCard";

type AssembleiasCardPropsContent = {
    data: any;
    skeleton?: never;
}

type AssembleiasCardPropsSkeleton = {
    data?: never;
    onClick?: never;
    onRemove?: never;
    skeleton: true;
}

type AssembleiasCardProps = AssembleiasCardPropsContent | AssembleiasCardPropsSkeleton;

export default function DashboardAssembleiasCredenciadasCard(props: AssembleiasCardProps){

    const navigate = useNavigate();
    
    function handleClick(){
        if(!props.skeleton && props.data){
            navigate("/assembleiaCredenciada/"+props.data.assembleiaId);
        }
    }

    let skeletonBadge = "";
    if(props.skeleton){
        skeletonBadge = "skeleton skeleton-badge";
    }

    let badgeText : Color = "dark";

    return (
        <>
            <ListCard onClick={handleClick}>
                <ListCard.Content>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Nome da empresa</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.clientes.join(", ") ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>N.º do processo</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.numeroProcesso ?? "..."}</ListCard.Text>
                    </ListCard.Item>
                    <ListCard.Item>
                        <ListCard.Title skeleton={props.skeleton}>Próximo Ato</ListCard.Title>
                        <ListCard.Text skeleton={props.skeleton}>{props.data?.dataProximoAto ? moment(props.data?.dataProximoAto).format("DD/MM/yyyy HH:mm") : ""}</ListCard.Text>
                    </ListCard.Item>
                </ListCard.Content>
                <ListCard.Action>
                    <Badge className={`${skeletonBadge}`} text={badgeText} bg="" style={{backgroundColor: props.data?.status.cor}}>{props.data?.status.descricao ?? "..."}</Badge>
                </ListCard.Action>
            </ListCard>
        </>
    )
}